import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import TheContainer from '@/components/layouts/TheContainer.vue'
import Home from '@/views/Home.vue';
import TapeCracker from '@/views/TapeCracker.vue';
import RunPricingModel from '@/views/run-pricing-model/RunPricingModel.vue';
import Scenarios from '@/views/run-pricing-model/Scenarios.vue';
import Scenario from '@/views/run-pricing-model/Scenario.vue';
import GlobalRule from '@/views/run-pricing-model/GlobalRule.vue';
import GlobalRules from '@/views/run-pricing-model/GlobalRules.vue';
import LocalRules from '@/views/run-pricing-model/LocalRules.vue';
import LocalRule from '@/views/run-pricing-model/LocalRule.vue';
import ParameterAdjustment from '@/views/run-pricing-model/ParameterAdjustment.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    component: TheContainer,
    children: [
      {
        name: 'Home',
        path: '',
        component: Home,
        meta: {
          requireAuth: true
        }
      },
      {
        name: 'TapeCracker',
        path: 'tapeCracker',
        component: TapeCracker,
        meta: {
          breadcrumb: {
            label: 'Tape Cracker',
            parent: 'Home'
          }
        }
      },
      {
        name: 'RunPricingModel',
        path: 'runPricingModel',
        component: RunPricingModel,
        props: true,
        meta: {
          breadcrumb: {
            label: 'Run Pricing Model',
            parent: 'Home'
          }
        }
      },
      {
        name: 'GlobalRule',
        path: 'globalRule/:id?/:from?',
        component: GlobalRule,
        meta: {
          breadcrumb: {
            label: 'Global Rule',
            parent: 'FromPath'
          }
        },
      },
      {
        name: 'LocalRule',
        path: 'localRule/:id?/:from?',
        component: LocalRule,
        meta: {
          breadcrumb: {
            label: 'Local Rule',
            parent: 'FromPath'
          }
        },
      },
      {
        name: 'ParameterAdjustment',
        path: 'parameterAdjustment/:id?/:from?',
        component: ParameterAdjustment,
        meta: {
          breadcrumb: {
            label: 'Parameter Adjustment',
            parent: 'LocalRule'
          }
        },
      },
      {
        name: 'GlobalRules',
        path: 'globalRules',
        component: GlobalRules,
        meta: {
          breadcrumb: {
            label: 'Global Rules',
            parent: 'Home'
          }
        },
      },
      {
        name: 'LocalRules',
        path: 'localRules',
        component: LocalRules,
        meta: {
          breadcrumb: {
            label: 'Local Rules',
            parent: 'Home'
          }
        },
      },
      {
        name: 'Scenarios',
        path: 'scenarios',
        component: Scenarios,
        meta: {
          breadcrumb: {
            label: 'Scenarios',
            parent: 'Home'
          }
        },
      },
      {
        name: 'Scenario',
        path: 'scenario/:id?',
        component: Scenario,
        meta: {
          breadcrumb: {
            label: 'Scenario Builder',
            parent: 'Scenarios'
          }
        },
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
