
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { MappingOptionsValidations } from '@/constants/validations/mapping-options.validation';
import { CDataTable } from '@coreui/vue';
import { IMapping } from '@/interfaces/importMapping/mapping.interface';
import _ from 'lodash';
import EntityDataModule from '@/store/modules/entity-data.module';
import { getModule } from 'vuex-module-decorators';
import { IgnoreColumn } from '@/constants/stateConstants';
import { validationMixin } from 'vuelidate';
import { isDuplicate } from '@/utils/validators';
import MappingRuleModal from './MappingRuleModal.vue';
import TapeCrackerModule from '@/store/modules/tape-cracker.module';
import { withPopper } from '@/plugins/popover';
import { ILoanRawPropertyMetadata } from '@/interfaces/loanRaws/loan-raw-property-metadata.interface';

const entityDataModule = getModule(EntityDataModule);

@Component({
  components: {
    'mapping-rule-modal': MappingRuleModal
  },
  mixins: [validationMixin],
  validations: MappingOptionsValidations
})
export default class MappingTable extends Vue {
  protected mappingOptions: IMapping[] = [];

  public get form(): IMapping[] {
    if (this.isAutofiltering) {
      this.mappingOptions = this.getFilteredMappingOptions();
    }
    return this.mappingOptions;
  }

  public get metadataOptions(): any[] {
    return [
      { value: IgnoreColumn, label: IgnoreColumn },
      ...this.metadata!.map((m: ILoanRawPropertyMetadata) => {
        return {
          value: m.name,
          label: m.name
        };
      })
    ];
  }
  @Prop({ required: true, default: [] }) public data: IMapping[];

  public get metadata(): ILoanRawPropertyMetadata[] | undefined {
    return _.orderBy(entityDataModule.loanRawPropertiesMetadata ?? [], [
      'name'
    ]);
  }

  public get mandatoryDbColumns(): string[] | undefined {
    if (entityDataModule.loanRawPropertiesMetadata) {
      return entityDataModule.loanRawPropertiesMetadata
        .filter(m => m.isRequired)
        .map(m => m.name);
    }
    return [];
  }

  public fields: any[] = [
    'fileColumn',
    'dbColumn',
    { key: IgnoreColumn, _classes: 'ignore-header' },
    'hashing',
    { key: 'dataSample', _style: 'max-width: 200px;' },
    { key: 'dataType', _style: 'min-width: 120px;' },
    { key: 'nulls', _style: 'min-width: 40px;' },
    { key: 'confidenceLevel', label: 'Confidence', _style: 'width:1%' },
    { key: 'matchType', _style: 'min-width: 140px;' }
  ];

  public isHashingRuleModalShow: boolean = false;
  public isAutofiltering: boolean = false;
  public currentMappingOption: IMapping | null = null;
  public popover = withPopper;

  public mounted(): void {
    this.mappingOptions = this.getFilteredMappingOptions();
    this.setFormState();
  }

  public openHashingRuleModal(mappingOption: IMapping): void {
    this.isHashingRuleModalShow = true;
    this.currentMappingOption = mappingOption;
  }

  public closeHashingRuleModal(rulesChanged: boolean): void {
    this.isHashingRuleModalShow = false;
    this.currentMappingOption = null;

    if (rulesChanged) {
      entityDataModule.getLoanRawMetadata().then();
    }
  }

  public filterTable(): void {
    this.mappingOptions = this.getFilteredMappingOptions();
  }

  public setAutofiltering(): void {
    this.isAutofiltering = !this.isAutofiltering;
  }

  public getUUId(): string {
    return `${_.uniqueId()}`;
  }

  public getBadge(confidenceLevel: string): string {
    switch (confidenceLevel) {
      case 'High':
        return 'success';
      case 'Medium':
        return 'warning';
      case 'Low':
        return 'danger';
      default:
        return 'primary';
    }
  }

  public getRulesCount(dbColumnName: string): number {
    return (
      entityDataModule.loanRawPropertiesMetadata!.find(
        (p) => p.name === dbColumnName
      )?.rulesCount ?? 0
    );
  }

  public ignoreAllUnmapped(): void {
    this.mappingOptions.forEach((value: IMapping, index: number) => {
      if (
        !value.dbColumn ||
        value.dbColumn === 'NaN' ||
        !entityDataModule.loanRawPropertiesMetadata!.find(
          (m) => m.name === value.dbColumn
        )
      ) {
        value.dbColumn = IgnoreColumn;
      }

      this.validateField('dbColumn', index);
    });

    this.updateData();
  }

  public onIgnore(item: IMapping, index: number, event: Event): void {
    const target = event.target as HTMLInputElement;
    const defaultToNull: any = null;

    const mappingOption: IMapping | undefined = _.find(
      this.mappingOptions,
      (option) => option.fileColumn === item.fileColumn
    );

    if (mappingOption) {
      mappingOption.dbColumn = target.checked ? IgnoreColumn : defaultToNull;
      this.validateField('dbColumn', index);
      this.updateData();
    }
  }

  public isDuplicate(item: IMapping): boolean {
    return isDuplicate(
      item,
      this.form.filter((i) => i.dbColumn && i.dbColumn !== IgnoreColumn),
      'dbColumn'
    );
  }

  public get isDirty(): boolean {
    return this.$v.form.$anyDirty;
  }

  public get isValid(): boolean {
    return !this.$v.form.$invalid;
  }

  public validateField(fieldName: string, index: number): boolean {
    const isValid: boolean = this.checkIfValid(fieldName, index);

    if (this.$v.form.$each && this.$v.form.$each!.$iter[index]) {
      this.$v.form.$each!.$iter[index]![fieldName].$touch();
    }
    this.setFormState();

    return isValid;
  }

  public checkIfValid(fieldName: string, index: number): boolean {
    if (this.$v.form.$each) {
      const model = this.$v.form.$each.$iter[index];
      if (!model || !model[fieldName]) {
        return false;
      }
      return !model[fieldName].$invalid;
    }

    return false;
  }

  public onColumnMap(item: IMapping, dbColumn: string, index: number): void {
    const mappingOption: IMapping | undefined = _.find(
      this.mappingOptions,
      (option) => option.fileColumn === item.fileColumn
    );

    if (dbColumn == null || dbColumn === IgnoreColumn) {
      mappingOption!.dbColumn = dbColumn;
      mappingOption!.nulls = null;
      mappingOption!.dataType = null;
      mappingOption!.matchType = null;
      mappingOption!.confidenceLevel = null;
    } else {
      const metadata:
        | ILoanRawPropertyMetadata
        | undefined = this.metadata!.find((m) => m.name === dbColumn);

      if (metadata != null && metadata !== undefined) {
        mappingOption!.dbColumn = metadata.name;
        mappingOption!.nulls = metadata.isRequired;
        mappingOption!.dataType = metadata.type;
        mappingOption!.matchType = 'Exact';
        mappingOption!.confidenceLevel = 'High';
      }
    }

    this.validateField('dbColumn', index);
    this.updateData();
  }

  public getFilteredMappingOptions(): IMapping[] {
    const unmappedOptions: IMapping[] = _.orderBy(
      this.data.filter(
        (i) =>
          !i.dbColumn ||
          i.dbColumn === 'NaN' ||
          !entityDataModule.loanRawPropertiesMetadata!.find(
            (m) => m.name === i.dbColumn
          )
      ),
      ['dbColumn'],
      ['desc']
    );

    const counts = _.countBy(this.data, (item) => item.dbColumn);
    const duplicateOptions: IMapping[] = _.orderBy(
      this.data.filter(
        (i) =>
          !unmappedOptions.some((uo) => uo === i) &&
          i.dbColumn &&
          counts[i.dbColumn] > 1
      ),
      ['dbColumn'],
      ['desc']
    );

    const mappedWithoutDuplicates: IMapping[] = this.data.filter(
      (i) =>
        !unmappedOptions.some((uo) => uo === i) &&
        i.dbColumn &&
        counts[i.dbColumn] === 1
    );

    const mappedWithoutByLowConfidance = mappedWithoutDuplicates.filter(
      (i) => i.confidenceLevel === 'Low'
    );
    const mappedWithoutByMediumConfidance = mappedWithoutDuplicates.filter(
      (i) => i.confidenceLevel === 'Medium'
    );
    const mappedWithoutByHighConfidance = mappedWithoutDuplicates.filter(
      (i) => i.confidenceLevel === 'High'
    );

    const mappedOptions: IMapping[] = _.concat(
      mappedWithoutByLowConfidance,
      mappedWithoutByMediumConfidance,
      mappedWithoutByHighConfidance
    );

    return _.concat(unmappedOptions, duplicateOptions, mappedOptions);
  }

  @Emit('update:data')
  public updateData(): IMapping[] | undefined {
    return this.mappingOptions;
  }

  @Emit('form-valid')
  public setFormState(): boolean {
    const items = this.form.filter(
      (v: any) =>
        v.dbColumn && v.dbColumn !== 'NaN' && v.dbColumn !== IgnoreColumn
    );

    const dbColumns = items.map(x => x.dbColumn);
    const itemCounts = _.countBy(items, (item) => item.dbColumn);
    return !this.$v.form.$invalid && !_.some(itemCounts, (d) => d > 1) &&
      (this.mandatoryDbColumns?.every(x => dbColumns.includes(x)) ?? false);
  }
}
