import { IScenario } from '@/interfaces/runPricingModel/scenario/scenario.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { IVectorSegmentParam } from '@/interfaces/runPricingModel/parameters/vectorSegmentParam.interface';
import { ApiBaseService } from './api-base-service';
import { IScenarioDetails } from '@/interfaces/runPricingModel/scenario/scenarioDetails.interface';

class ScenarioService extends ApiBaseService {
    private baseEndpoint = "/scenarios";

    public getScenarios = (searchState: FiltrationState): Promise<PaginatedResult<IScenario>> =>
        this.getFromSearchParams<IScenario>(this.baseEndpoint, searchState);

    public getScenario = (id: number): Promise<IScenarioDetails> => this.getById<IScenarioDetails>(this.baseEndpoint, id);

    public createScenario = (scenario: IScenarioDetails): Promise<any> => this.post<any>(this.baseEndpoint, scenario);

    public updateScenario = (id: number, scenario: IScenarioDetails): Promise<number> => this.put<number>(this.baseEndpoint, id, scenario);

    public getExistScenarioOrDefault(name: string, id?: number): Promise<IScenario> {
        let url: string = `${this.baseEndpoint}/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return this.getWithHeaders<IScenario>(url, { useGlobalLoader: false, useToastNotification: false });
    }

    public remove = (id: number): Promise<object> => this.delete<object>(this.baseEndpoint, id);

    public generateSegment = (segment: IVectorSegmentParam): Promise<any> =>
        this.post<any>(`${this.baseEndpoint}/generateSegment`, segment);
}

export default new ScenarioService();