import { IGlobalRule } from '@/interfaces/runPricingModel/globalRule.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { ApiBaseService } from './api-base-service';

class GlobalRuleService extends ApiBaseService {
    private baseEndpoint ="/globalRules";

    public getGlobalRules = (searchState: FiltrationState): Promise<PaginatedResult<IGlobalRule>> =>
        this.getFromSearchParams<IGlobalRule>(this.baseEndpoint, searchState);

    public getGlobalRule = (id: number): Promise<IGlobalRule> => this.getById<IGlobalRule>(this.baseEndpoint, id);

    public createGlobalRule = (globalRule: IGlobalRule): Promise<any> => this.post<any>(this.baseEndpoint, globalRule);

    public updateGlobalRule = (id: number, globalRule: IGlobalRule): Promise<number> =>
        this.put<number>(this.baseEndpoint, id, globalRule);

    public getExistGlobalRuleOrDefault(name: string, id?: number): Promise<IGlobalRule> {
        let url: string = `${this.baseEndpoint}/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return this.getWithHeaders<IGlobalRule>(url, { useGlobalLoader: false, useToastNotification: false });
    }

    public copyGlobalRule = (id: number): Promise<number> => this.copy<number>(this.baseEndpoint, id);

    public getParameters = (id: number): Promise<string> => this.get<string>(`/globalRules/${id}/parameters`);
}

export default new GlobalRuleService();