
import { Component, Vue } from "vue-property-decorator";
import { withPopper } from "@/plugins/popover";
import LocalRuleService from "@/services/local-rule.service";
import { ParametersService } from "@/services/parameters.service";
import { operationTypes } from "@/components/run-pricing-model/parameters/constants/operationTypes.const";
import { PaginatedResult } from "@/models/paginatedResult";
import { FiltrationState } from "@/models/filtrationState";
import { maxItemsInDropDown } from "@/constants/stateConstants";
import { ILocalRule } from "@/interfaces/runPricingModel/localRule.interface";

@Component({
  methods: {},
  data() {
    return {
      isRouteLeaving: false,
      isRedirectToPrevious: false,
      nextRouteName: String,
      operationTypes,
      nextRoute: Function,
      parametersService: ParametersService,
    };
  },
})
export default class LocalRules extends Vue {
  // refs

  public popover = withPopper;
  public ruleModalText: string = "";
  public ruleModalShow: boolean = false;
  private numberOfRulesLoaded: number;
  public numberOfItemsLoaded: number = 0;
  public isLoadMore: boolean;
  public search: string | null = null;
  public rules: ILocalRule[] = [];

  public fields: any = [
    { key: "name", _style: "width:200px" },
    "description",
    { key: "buttons", label: "Actions", _style: "width:90px" },
  ];

  public get rulesOptions(): any[] {
    return [
      ...this.rules.map((rule: ILocalRule, index: number) => {
        return {
          id: rule.id,
          name: rule.name,
          description: rule.description,
          _classes: index % 2 > 0 ? "odd-row" : "even-row",
        };
      }),
    ];
  }

  public constructor() {
    super();
    const state: FiltrationState = this.createSearchState(null, 0);
    this.loadLocalRules(state, false);
  }

  public created(): void { }

  public openRuleModal(ruleId: number): void {
    LocalRuleService.getParameters(ruleId).then((value) => {
      this.ruleModalText = value;
    });

    this.ruleModalShow = true;
  }

  public closeRuleModal(): void {
    this.ruleModalShow = false;
  }

  public searchRules(value: string): void {
    const state: FiltrationState = this.createSearchState(value, 0);
    this.loadLocalRules(state, false);
  }

  private loadLocalRules(state: FiltrationState, isLoadMore: boolean) {
    LocalRuleService.getLocalRules(state).then(
      (response: PaginatedResult<ILocalRule>) => {
        if (isLoadMore) {
          for (const localRule of response.entities) {
            this.rules.push(localRule);
          }
        } else {
          this.rules = response.entities;
        }

        this.numberOfRulesLoaded += response.entities.length;
        if (response.totalCount <= this.numberOfRulesLoaded) {
          this.numberOfRulesLoaded = 0;
        }
      }
    );
  }

  public createSearchState(
    search: string | null,
    skipCount: number
  ): FiltrationState {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfRulesLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }
    return state;
  }

  public editRule(ruleId: number): void {
    this.$router.push({
      name: "LocalRule",
      params: { id: ruleId.toString(), from: "LocalRules" },
    });
  }
}
