
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { IVectorSegmentParam } from "@/interfaces/runPricingModel/parameters/vectorSegmentParam.interface";
import { validationMixin } from "vuelidate";
import { VectorSegmentParamsValidations } from "@/constants/validations/vesctor-segement-params.validation";
import { checkIfValidInArray } from "@/utils/validators";
import { IVectorParamValue } from "@/interfaces/runPricingModel/parameters/vectorParamValue.interface";

import SegmentOutput from "./SegmentOutput.vue";

@Component({
  components: {
    "segment-output": SegmentOutput,
  },
  mixins: [validationMixin],
  validations: VectorSegmentParamsValidations,
})
export default class VectorParamSegmentsModal extends Vue {
  @Prop({ required: false, default: () => [] })
  public segments: IVectorSegmentParam[];
  public isSegmentProcessed: boolean = true;

  public get form(): IVectorSegmentParam[] {
    return this.segments;
  }

  public constructor() {
    super();
  }

  public isControlValid(fieldName: string, index: number): boolean {
    return checkIfValidInArray(this.$v.form, fieldName, index);
  }

  @Emit("close")
  public onClose(): void {
    return;
  }

  @Emit("submit")
  public onSubmit(): IVectorParamValue {
    return {
      segments: this.segments,
      rangeValue: null,
    };
  }

  public addSegment(): void {
    this.segments.push({
      vectorLength: null,
      start: null,
      end: null,
    });
  }

  public isSegmentValid(segment: any): boolean {
    return !(
      segment.start.$invalid ||
      segment.end.$invalid ||
      segment.vectorLength.$invalid
    );
  }

  public removeSegment(segment: any): void {
    if (this.segments) {
      const index = this.segments.indexOf(segment);
      if (index > -1) {
        this.segments.splice(index, 1);
      }
    }
  }

  public isSegmentProcessing(isProcessing: boolean) {
    this.isSegmentProcessed = isProcessing;
  }
}
