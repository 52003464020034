import { render, staticRenderFns } from "./FileUploader.vue?vue&type=template&id=48f4cb08&scoped=true"
import script from "./FileUploader.vue?vue&type=script&lang=ts"
export * from "./FileUploader.vue?vue&type=script&lang=ts"
import style0 from "./FileUploader.vue?vue&type=style&index=0&id=48f4cb08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f4cb08",
  null
  
)

export default component.exports