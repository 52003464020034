
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import httpClient from '@/plugins/httpClient';
import { QueryBuilderService } from './query-builder.service';


export abstract class ApiBaseService {
    protected getFromSearchParams<T>(url: string, searchState: FiltrationState | undefined = undefined): Promise<PaginatedResult<T>> {
        const searchParams: URLSearchParams = QueryBuilderService.BuildParametersFromSearch(searchState);
        return httpClient.get<PaginatedResult<T>>(`${url}`, { params: searchParams });
    }

    protected getWithHeaders<T>(url: string, headerParams: object | undefined = undefined): Promise<T> {
        return httpClient.get<T>(`${url}`, { headers: headerParams });
    }

    protected get<T>(url: string): Promise<T> {
        return httpClient.get<T>(url);
    }

    protected getById<T>(url: string, id: number): Promise<T> {
        return httpClient.get<T>(`${url}/${id}`);
    }

    protected post<T>(url: string, body: any, headers?: any): Promise<any> {
        return httpClient.post<T>(`${url}`, body, headers)
    }
    
    protected put<T>(url: string, id: number, body: any): Promise<any> {
        return httpClient.put(`${url}/${id}`, body)
    }
    
    protected copy<T>(url: string, id: number): Promise<T> {
        return httpClient.post<T>(`${url}/${id}/copy`);
    }

    protected delete<T>(url: string, id: number): Promise<T> {
        return httpClient.delete<T>(`${url}/${id}`);
    }
}