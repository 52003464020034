import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { IDataSource } from '@/interfaces/dataSources/data-source.interface';
import { ApiBaseService } from './api-base-service';

class DataSourceService extends ApiBaseService{
    private baseEndpoint = "/dataSources"
    
    public getDataSources = (searchState: FiltrationState) : Promise<PaginatedResult<IDataSource>> => this.getFromSearchParams<IDataSource>(this.baseEndpoint, searchState)
}

export default new DataSourceService();
