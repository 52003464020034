import { IParameterAdjustment } from '@/interfaces/runPricingModel/parameterAdjustment.interface';
import { IParameterAdjustmentDetails } from '@/interfaces/runPricingModel/parameterAdjustmentDetails.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { ApiBaseService } from './api-base-service';

class ParameterAdjustmentService extends ApiBaseService {
    private baseEndpoint = '/parameterAdjustments';

    public getParameterAdjustments = (searchState: FiltrationState) : Promise<PaginatedResult<IParameterAdjustment>> => this.getFromSearchParams<IParameterAdjustment>(this.baseEndpoint, searchState)

    public getParameterAdjustment = (id: number) : Promise<IParameterAdjustmentDetails> => this.getById<IParameterAdjustmentDetails>(this.baseEndpoint, id);

    public createParameterAdjustment = (parameterAdjustment: IParameterAdjustmentDetails) : Promise<any> => this.post<any>(this.baseEndpoint, parameterAdjustment);

    public updateParameterAdjustment = (id: number, parameterAdjustment: IParameterAdjustmentDetails) : Promise<number> => this.put<number>(this.baseEndpoint, id, parameterAdjustment)

    public copyParameterAdjustment = (id: number) : Promise<number> => this.copy<number>(this.baseEndpoint, id);

    public getExistParameterAdjustmentsOrDefault(name: string, id?: number): Promise<IParameterAdjustment> {
        let url: string = `${this.baseEndpoint}/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return this.getWithHeaders<IParameterAdjustment>(url, { useGlobalLoader: false, useToastNotification: false } );
    }
}

export default new ParameterAdjustmentService();