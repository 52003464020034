
import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { RunPricingModelValidations } from "@/constants/validations/run-pricing-model.validation";
import {
  IExecution,
  IRunPricingModel,
} from "@/interfaces/runPricingModel/runPricingModel.interface";
import { ILoanPool } from "@/interfaces/runPricingModel/loanPool.interface";
import { IRevision } from "@/interfaces/runPricingModel/revision.interface";
import { IScenario } from "@/interfaces/runPricingModel/scenario/scenario.interface";
import { withPopper } from "@/plugins/popover";
import { FiltrationState } from "@/models/filtrationState";
import { maxItemsInDropDown } from "@/constants/stateConstants";
import DealService from "@/services/deal.service";
import ScenarioService from "@/services/scenario.service";
import { PaginatedResult } from "@/models/paginatedResult";
import { getModule } from "vuex-module-decorators";
import { IScenarioDetails } from "@/interfaces/runPricingModel/scenario/scenarioDetails.interface";
import RunPricingService from "@/services/run-pricing.service";
import AzureDataService from "@/services/azure-data.service";
import PermissionsModule from "@/store/modules/permissions.module";
import { ApiResponseType } from "@/enums/api-response-type";
import { validPromise } from "@/utils/validPromise";
import RulesList from "@/components/run-pricing-model/RulesList.vue";

@Component({
  mixins: [validationMixin],
  validations: RunPricingModelValidations,
  components: {
    "rules-list": RulesList,
  }
})
export default class RunPricingModel extends Vue {
  public get loanPoolOptions(): any[] {
    return [
      ...this.loanPool.map((it: ILoanPool) => {
        return {
          value: it.id,
          label: it.name,
        };
      }),
    ];
  }

  public get revisionOptions(): any[] {
    return [
      ...this.revisions.map((it: IRevision) => {
        return {
          value: it.id,
          label: it.name,
        };
      }),
    ];
  }

  public get modelVersionOptions(): any[] {
    return [...this.modelVersions];
  }

  public get scenarioOptions(): any[] {
    return [
      ...this.scenarios.map((it: IScenario) => {
        return {
          value: it.id,
          label: it.name,
        };
      }),
    ];
  }

  public form = this.getEmptyForm();
  public loanPool: ILoanPool[] = [];
  public revisions: IRevision[] = [];
  public modelVersions: string[] = [];
  public scenarios: IScenario[] = [];
  public popover = withPopper;
  public search: string | null = null;
  public selectedScenario: IScenarioDetails | null = null;
  public numberOfItemsLoaded: number = 0;
  public isLoadMore: boolean;
  public ruleModalText: string = "";
  public ruleModalShow: boolean = false;
  private permissionsModule: PermissionsModule;

  public scenarioForm = this.getEmptyScenarioForm();
  public dsUserName = "dsUser";
  public isSaveExecution: boolean = false;
  public isRunPricingModel: boolean = false;
  public isSaveAfterSubmit: boolean = false;

  private selectedExecutionId: number | null = null;
  private existingExecutionCollapse: boolean = false;

  public constructor() {
    super();
    this.permissionsModule = getModule(PermissionsModule);
  }

  protected getEmptyForm(): IRunPricingModel {
    return {
      dealId: null,
      revisionId: null,
      description: "",
      scenarioId: null,
      modelImageTag: null,
      runPricing: false,
    };
  }

  protected getEmptyScenarioForm(): IScenarioDetails {
    return {
      name: "",
      id: null,
      localRules: [],
      globalRules: [],
    };
  }

  public created(): void {
    this.searchDeals(null, 0, false);
    this.searchScenarios(null, 0, false);
    this.loadModelVersions();
  }

  public checkUserRole(roleToCheck: string): boolean {
    const userRoles = this.permissionsModule.getCurrentUserRoles;
    if (userRoles) {
      return userRoles.roles.includes(roleToCheck);
    }
    return false;
  }

  public getModelPlaceholder(): string | null {
    return this.form.modelImageTag ?? "Model version";
  }

  public setEmptyForms(): void {
    this.form = this.getEmptyForm();
    this.scenarioForm = this.getEmptyScenarioForm();
  }

  public resetButtonFlags(): void {
    this.selectedScenario = null;
  }

  public async scenarioChanged(): Promise<void> {
    await this.getScenarioInfo();
    await validPromise.call(this, "scenarioForm");
  }

  public saveExecutionPressed(): void {
    this.validate();
    const isFormDirtyAndValid =
      (!this.form.scenarioId && this.scenarioForm.name.length > 0) &&
      !this.$v.form.$invalid;

    if (!isFormDirtyAndValid) {
      this.runPricingModel(false);
      return;
    }

    this.isSaveExecution = true;
  }

  public runPricingModelPressed(): void {
    this.validate();
    const isFormDirtyAndValid =
      (!this.form.scenarioId && this.scenarioForm.name.length > 0) &&
      !this.$v.form.$invalid;

    if (!isFormDirtyAndValid) {
      this.runPricingModel(true);
      return;
    }

    this.isRunPricingModel = true;
  }

  public validate(): void {
    this.$v.$touch();
    this.$v.form.$touch();
  }

  public validateScenarioForm(): void {
    this.$v.form.scenarioId?.$touch();
    this.$v.scenarioForm.$touch();
  }

  public searchDeals(
    search: string | null,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfItemsLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }

    this.loadDeals(state, isLoadMore);
  }

  public searchScenarios(
    search: string | null,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfItemsLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }

    this.loadScenarios(state, isLoadMore);
  }

  public loanPoolChanged(id: number): void {
    const loanPool = this.loanPool.find((value) => {
      return value.id === id;
    });
    if (loanPool) {
      this.revisions = loanPool.revisions.sort((a, b) => b.id - a.id);
      this.form.revisionId = loanPool.revisions[0].id;
    } else {
      this.form.revisionId = null;
    }
  }

  public async getScenarioInfo(): Promise<void> {
    if (this.form.scenarioId) {
      ScenarioService.getScenario(this.form.scenarioId).then((scenario) => {
        this.selectedScenario = scenario;
        this.scenarioForm = scenario;
      });
    } else {
      this.scenarioForm = this.getEmptyScenarioForm();
      this.selectedScenario = null;
    }
  }

  public async runPricingModel(runPricing: Boolean): Promise<void> {
    this.validate();
    if (!this.$v.form.$invalid) {
      RunPricingService.runPricingModel(this.form, runPricing).then(() => {
        this.reset();
        Vue.$toast.clear();
        if (runPricing) {
          Vue.$toast.success(`Pricing model executed`);
        } else {
          Vue.$toast.success(`Pricing model execution saved`);
        }
      });
    }
  }

  public deleteExecution(executionId: number): void {
    RunPricingService.deleteExecution(executionId)
      .then((result) => {
        this.selectedExecutionId = null;
        Vue.$toast.clear();
        if (result.type === ApiResponseType.Success) {
          Vue.$toast.success(result.message);
        } else {
          Vue.$toast.error(result.message);
        }
      })
      .catch((error: any) => {
        Vue.$toast.error(error.message);
      });
  }

  public runExistingExecution(): void {
    if (!this.selectedExecutionId) {
      return;
    }
    RunPricingService.runExistingExecution(this.selectedExecutionId)
      .then(() => {
        Vue.$toast.clear();
        Vue.$toast.success(`Pricing model executed`);
      })
      .catch((error: any) => {
        Vue.$toast.error(error.message);
      });
  }

  public openExecutionModal(): void {
    RunPricingService.getExecution(this.selectedExecutionId ?? 0).then(
      (value: IExecution) => {
        let text = `${value.globalRules}${value.localRules}`;
        this.ruleModalText = text;
      }
    );

    this.ruleModalShow = true;
  }

  public closeRuleModal(): void {
    this.ruleModalShow = false;
  }

  private reset(): void {
    this.form = this.getEmptyForm();
    this.$v.form.$reset();
    this.selectedScenario = null;
    this.scenarioForm = this.getEmptyScenarioForm();
    if (!this.checkUserRole(this.dsUserName)) {
      this.form.modelImageTag = this.modelVersions[this.modelVersions.length - 1];
    }
  }

  private loadDeals(state: FiltrationState, isLoadMore: boolean): void {
    DealService.getDealsForLoanPool(state).then(
      (resp: PaginatedResult<ILoanPool>) => {
        if (isLoadMore) {
          for (const deal of resp.entities) {
            this.loanPool.push(deal);
          }
        } else {
          this.loanPool = resp.entities;
        }

        this.numberOfItemsLoaded += resp.entities.length;
        if (resp.totalCount > this.numberOfItemsLoaded) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
          this.numberOfItemsLoaded = 0;
        }
        if (this.form.dealId) {
          const loanPool = this.loanPool?.find((value) => {
            return value.id === this.form.dealId;
          });
          if (loanPool) {
            this.revisions = loanPool.revisions.sort((a, b) => b.id - a.id);
          }
        }
      }
    );
  }

  private loadScenarios(state: FiltrationState, isLoadMore: boolean): void {
    ScenarioService.getScenarios(state).then(
      (resp: PaginatedResult<IScenario>) => {
        if (isLoadMore) {
          for (const scenario of resp.entities) {
            this.scenarios.push(scenario);
          }
        } else {
          this.scenarios = resp.entities;
        }

        this.numberOfItemsLoaded += resp.entities.length;
        if (resp.totalCount > this.numberOfItemsLoaded) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
          this.numberOfItemsLoaded = 0;
        }
      }
    );
  }

  private loadModelVersions(isForceRefresh: boolean = false): void {
    AzureDataService.getModelVersions(isForceRefresh)
      .then((resp: string[]) => {
        this.modelVersions = resp;
        if (!this.checkUserRole(this.dsUserName)) {
          this.form.modelImageTag = resp[resp.length - 1];
        }
      })
      .catch((error) => {
        this.modelVersions = [];
        this.$toast.error(error.message);
      });
  }
}
