import { IParamData } from '@/interfaces/runPricingModel/parameters/commonParam.interface';
import { IRunPricingColumn, RunPricingColumnType, RunPricingDataType } from '@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface';
import { NameValuePair } from '@/models/NameValuePair';

export const isRunPricingParameterValid = (parameter: IParamData, columns: IRunPricingColumn[]): boolean => {

    if (parameter.columnType === RunPricingColumnType.TableParam) {
        let isColumnsValid: boolean = true;
        const tableColumn: IRunPricingColumn | undefined = columns.find(c => c.columnName === parameter.columnName);
        
        if (tableColumn) {
            parameter.value.every((item: NameValuePair, index: number) => {
                if (item.value) {
                    switch (tableColumn.tableColumns[index].dataType) {
                        case RunPricingDataType.LIST_INT:
                            isColumnsValid = isListOfInts(item.value) && (isListInDescendingOrder(item.value) || parameter.columnName === 'TBATable');
                            break;
                        case RunPricingDataType.LIST_FLOAT:
                            isColumnsValid = isListOfFloats(item.value) && (isListInDescendingOrder(item.value) || parameter.columnName === 'TBATable');
                            break;
                        default:
                            isColumnsValid = true;
                            break;
                    }
                    if (!isColumnsValid) {
                        return isColumnsValid;
                    }
                } else {
                    isColumnsValid = true;
                }
                return isColumnsValid;
            });
            if(isEitherOneIsNull(parameter.value[0].value, parameter.value[1].value) ||
               isSameCountOfNumbers(parameter.value[0].value, parameter.value[1].value)) {
                isColumnsValid = false;
            }
            return isColumnsValid;
        }

        return false;
    } else if (parameter.columnType === RunPricingColumnType.ConstructorParam ||
        parameter.columnType === RunPricingColumnType.PairedConstructorParam) {
        let isColumnsValid: boolean = true;
        if (!parameter.value) {
            return isColumnsValid;
        }
        if (parameter.columnType === RunPricingColumnType.PairedConstructorParam && !parameter.value[0].pair?.name) {
            isColumnsValid = false;
        }
        parameter.value.forEach((item: any) => {
            item.parametersMaps.forEach((parameterMap: { [key: string]: IParamData }) => {
                Object.keys(parameterMap).forEach((key: string) => {
                    const isColumnValid = isCommonTypeValid(parameterMap[key]);
                    var paramValue = parameterMap[key].value;
                    if ((paramValue !== "0" && !paramValue) || !isColumnValid) {
                        isColumnsValid = false;
                    }
                });
            });
            if (parameter.columnType === RunPricingColumnType.PairedConstructorParam) {
                item.pair.parametersMaps.forEach((parameterMap: { [key: string]: IParamData }) => {
                    Object.keys(parameterMap).forEach((key: string) => {
                        const isColumnValid = isCommonTypeValid(parameterMap[key]);
                        var paramValue = parameterMap[key].value;
                        if ((paramValue !== "0" && !paramValue) || !isColumnValid) {
                            isColumnsValid = false;
                        }
                    });
                });

            }
        });

        return isColumnsValid;
    } else {
        return isCommonTypeValid(parameter);
    }
}

export const isCommonTypeValid = (parameter: IParamData): boolean => {
    parameter.value = `${!parameter.isOperation ? parameter.inputValue : parameter.operationValue}`;
    switch (parameter.type) {
        case RunPricingDataType.INT:
            return isInt(parameter.value);
        case RunPricingDataType.FLOAT:
            return isFloat(parameter.value);
        case RunPricingDataType.BOOLEAN:
            return isBool(parameter.value);
        case RunPricingDataType.STRING:
            return isString(parameter.value);
        default:
            return true;
    }
}

export const isSameCountOfNumbers = (first: string, second: string): boolean => {
    return first.split(',').length != second.split(',').length
}

export const isEitherOneIsNull = (first: string, second: string): boolean => {
    return <boolean>((first && !second) || (!first && second));
}

export const isInt = (value: any): boolean => {
    if (!value || value?.match(/^-?\d+$/) != null) {
        return true;
    }
    return false;
}

export const isBool = (value: any): boolean => {
    if (!value || value?.match(new RegExp(/(true|false)/, 'gi')) != null) {
        return true;
    }
    return false;
}

export const isString = (value: any): boolean => {
    if (value) {
        return value.length < 100;
    }
    return true;
}

export const isFloat = (value: any): boolean => {
    if (!value ||
        value?.toString()
            .match(
                /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/
            ) != null) {
        return true;
    }
    return false;
}

export const isListOfInts = (value: any): boolean => {
    if (value &&
        value
            .toString()
            .match(/^(\d+(?:[ \t]*,[ \t]*\d+)*)?$/) != null) {
        return true;
    }
    return false;
}

export const isListOfFloats = (value: any): boolean => {
    if (value &&
        value
            .toString()
            .match(/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/) != null) {
        return true;
    }
    return false;
}

export const isRunPricingParameterEmpty = (parameter: IParamData, columns: IRunPricingColumn[]): boolean => {
    if (parameter.columnType === RunPricingColumnType.TableParam) {
        let isColumnsEmpty: boolean = true;
        const tableColumn: IRunPricingColumn | undefined = columns.find(c => c.columnName === parameter.columnName);

        if (tableColumn) {
            parameter.value.forEach((item: NameValuePair, index: number) => {
                if (item.value) {
                    isColumnsEmpty = false;
                }
            });

            return isColumnsEmpty;
        }

        return true;
    } else if (parameter.columnType === RunPricingColumnType.ConstructorParam ||
        parameter.columnType === RunPricingColumnType.PairedConstructorParam) {
        let isColumnsEmpty: boolean = true;

        parameter.value.forEach((item: any) => {
            if (item.parametersMaps && item.parametersMaps.length) {
                isColumnsEmpty = false;
            }
        });

        return isColumnsEmpty;
    } else {
        return isCommonTypeEmpty(parameter);
    }
}

export const isListInDescendingOrder = (value: any): boolean => {
    const arr = value.split(',');
    for (let i = 0; i < arr.length; i++) {
        if (+arr[i + 1] > +arr[i]) {
            return false;
        }
    }
    return true;
}

export const isCommonTypeEmpty = (parameter: IParamData): boolean => {
    let isColumnsEmpty: boolean = true;
    if (parameter.value === "0" || parameter.value) {
        isColumnsEmpty = false;
    }
    return isColumnsEmpty;
}