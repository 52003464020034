var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"home"},[_c('div',{staticClass:"row"},[_c('BreadCrumbs')],1),_c('c-row',[_c('c-col',{attrs:{"sm":"12","md":"12"}},[_c('c-card',{staticClass:"h-100 card-container m-0"},[_c('c-card-body',{staticClass:"card-body-container pb-0"},[_c('h3',{staticClass:"m-0 block-title"},[_vm._v("Scenario Builder")]),_c('c-input',{attrs:{"isValid":!(
              _vm.$v.form.name.$dirty &&
              _vm.$v.form.name.$invalid &&
              !_vm.$v.form.name.$pending
            ),"lazy":false,"value":_vm.$v.form.name.$model,"placeholder":"Enter the scenario name","addLabelClasses":{
                'invalid-data': _vm.$v.form.name.$dirty && _vm.$v.form.name.$invalid,
              }},on:{"change":_vm.updateName},scopedSlots:_vm._u([{key:"label-after-input",fn:function(){return [(_vm.$v.form.name.$dirty && !_vm.$v.form.name.$pending)?_c('div',[(!_vm.$v.form.name.required)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Scenario Name is required. ")]):_vm._e(),(!_vm.$v.form.name.unique)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Scenario Name already exists. ")]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Scenario Name must have at most "+_vm._s(_vm.$v.form.name.$params.maxLength.max)+" letters. ")]):_vm._e()]):_vm._e()]},proxy:true}])})],1),_c('rules-list',{key:`${_vm.form.id}-global`,attrs:{"isLocal":false,"existingRules":_vm.form.globalRules},on:{"update:existingRules":function($event){return _vm.$set(_vm.form, "globalRules", $event)},"update:existing-rules":function($event){return _vm.$set(_vm.form, "globalRules", $event)},"changingRule":_vm.changingRule,"receiveRulesData":_vm.receiveGlobalRules}}),_c('rules-list',{key:`${_vm.form.id}-local`,attrs:{"isLocal":true,"existingRules":_vm.form.localRules},on:{"update:existingRules":function($event){return _vm.$set(_vm.form, "localRules", $event)},"update:existing-rules":function($event){return _vm.$set(_vm.form, "localRules", $event)},"changingRule":_vm.changingRule,"receiveRulesData":_vm.receiveLocalRules}}),_c('div',{staticClass:"pb-4 text-center mt-3"},[_c('c-button',{staticClass:"btn-filled large",on:{"click":_vm.submit}},[_c('img',{staticClass:"mr-2",attrs:{"src":"/icons/save.svg"}}),_vm._v("Save Scenario")]),(
              _vm.$v.form.name.$dirty &&
              _vm.$v.form.name.$invalid &&
              !_vm.$v.form.name.$pending
            )?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Scenario Name should be valid. ")]):_vm._e()],1)],1)],1)],1)],1),(_vm.isRouteLeaving)?_c('confirm-operation-modal',{attrs:{"operationProp":'return',"useCustomMessageProp":true,"customMessageProp":'Are you sure you want to save changes?',"submitButtonTextProp":'Save',"discardButtonTextProp":'Discard',"cancelButtonTextProp":'Cancel',"disableButtonClose":true,"isCustomHeaderText":true,"customHeaderText":'Confirm',"isDiscardRequiredProp":true},on:{"submit":function($event){return _vm.submit($event)},"cancel":function($event){_vm.isRouteLeaving = false},"discard":function($event){return _vm.discardChanges($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }