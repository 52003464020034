import { IDeal } from '@/interfaces/deals/deal.interface';
import { ILoanPool } from '@/interfaces/runPricingModel/loanPool.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { ApiBaseService } from './api-base-service';

class DealService extends ApiBaseService {
    private baseEndpoint = "/deals";

    public getDeals = (searchState: FiltrationState): Promise<PaginatedResult<IDeal>> =>
        this.getFromSearchParams<IDeal>(this.baseEndpoint, searchState);

    public getDeal = (id: number): Promise<IDeal> => this.getById<IDeal>(this.baseEndpoint, id);

    public getDealsForLoanPool = (searchState: FiltrationState): Promise<PaginatedResult<ILoanPool>> =>
        this.getFromSearchParams<ILoanPool>(`${this.baseEndpoint}/loanPool`, searchState);

    public getExistDealOrDefault = (name: string): Promise<IDeal> => {
        return this.getWithHeaders<IDeal>(`${this.baseEndpoint}/exists?name=${name}`, { useGlobalLoader: false, useToastNotification: false });
    };
}

export default new DealService();