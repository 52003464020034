import { ILocalRule } from '@/interfaces/runPricingModel/localRule.interface';
import { ILocalRuleDetails } from '@/interfaces/runPricingModel/localRuleDetails.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { ApiBaseService } from './api-base-service';

class LocalRuleService extends ApiBaseService {
    private baseEndpoint = "/localRules";

    public getLocalRules = (searchState: FiltrationState): Promise<PaginatedResult<ILocalRule>> =>
        this.getFromSearchParams<ILocalRule>(this.baseEndpoint, searchState);

    public getLocalRule = (id: number): Promise<ILocalRuleDetails> => this.getById<ILocalRuleDetails>(this.baseEndpoint, id);

    public createLocalRule = (localRule: ILocalRuleDetails): Promise<any> => this.post<any>(this.baseEndpoint, localRule);

    public updateLocalRule = (id: number, localRule: ILocalRuleDetails): Promise<number> => this.put<number>(this.baseEndpoint, id, localRule);

    public copyLocalRule = (id: number): Promise<number> => this.copy<number>(this.baseEndpoint, id);

    public getExistLocalRuleOrDefault(name: string, id?: number): Promise<ILocalRule> {
        let url: string = `${this.baseEndpoint}/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return this.getWithHeaders<ILocalRule>(url, { useGlobalLoader: false, useToastNotification: false });
    }

    public getParameters = (id: number): Promise<string> => this.get<string>(`/localRules/${id}/parameters`);
}

export default new LocalRuleService();