import { ISubsetRule } from '@/interfaces/runPricingModel/subsetRule.interface';
import { ISubsetRuleDetails } from '@/interfaces/runPricingModel/subsetRuleDetails.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { ApiBaseService } from './api-base-service';
class SubsetRuleService extends ApiBaseService{
    private baseEndpoint = "/subsetRules";

    public getSubsetRules = (searchState: FiltrationState): Promise<PaginatedResult<ISubsetRule>> =>
        this.getFromSearchParams<ISubsetRule>(this.baseEndpoint, searchState);

    public getSubsetRule = (id: number): Promise<ISubsetRuleDetails> => this.getById<ISubsetRuleDetails>(this.baseEndpoint, id);

    public createSubsetRule = (subsetRule: ISubsetRuleDetails): Promise<any> => this.post<any>(this.baseEndpoint, subsetRule);

    public updateSubsetRule = (id: number, subsetRule: ISubsetRuleDetails): Promise<number> =>
        this.put<number>(this.baseEndpoint, id, subsetRule);

    public getExistSubsetRuleOrDefault(name: string, id?: number): Promise<ISubsetRule> {
        let url: string = `${this.baseEndpoint}/exists?name=${name}`;

        if (id) {
            url += `&id=${id}`;
        }
        return this.getWithHeaders<ISubsetRule>(url, { useGlobalLoader: false, useToastNotification: false });
    }

    public copySubsetRule = (id: number): Promise<ISubsetRule> => this.copy<ISubsetRule>(this.baseEndpoint, id);
}

export default new SubsetRuleService();