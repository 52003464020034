
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";
import { IVectorSegmentParam } from "@/interfaces/runPricingModel/parameters/vectorSegmentParam.interface";
import ScenarioService from "@/services/scenario.service";
import { getVectorRange } from "@/utils/vectorParam";

@Component({})
export default class SegmentOutput extends Vue {
  // properties
  @Prop({ required: true }) segment: any;

  public data: number[] = [];
  public receivedSegment: IVectorSegmentParam;

  public async created() {
    await this.update();
  }

  public async updated() {
    await this.update();
  }

  private isSegmentEqual(
    first: IVectorSegmentParam | null,
    second: IVectorSegmentParam | null
  ): boolean {
    return JSON.stringify(first) === JSON.stringify(second);
  }

  public async update() {
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
    if (
      !this.isSegmentEqual(this.receivedSegment, this.segment.$model) &&
      this.isSegmentValid(this.segment)
    ) {
      this.isSegmentProcessed(false);
      this.data = getVectorRange(this.segment.$model, 100);
      this.receivedSegment = JSON.parse(JSON.stringify(this.segment.$model));
      this.isSegmentProcessed(true);
    }
    }, 1000);
  }

  public isSegmentValid(segment: any): boolean {
    if (!segment || !segment.start || !segment.end || !segment.vectorLength)
      return false;
    return !(
      segment.start.$invalid ||
      segment.end.$invalid ||
      segment.vectorLength.$invalid
    );
  }

  @Emit("segment-processing")
  public isSegmentProcessed(processing: boolean) {
    return processing;
  }
}
