var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('c-row',{staticClass:"d-flex align-content-center"},[_c('c-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-3",on:{"click":function($event){_vm.constructorParamCollapse = !_vm.constructorParamCollapse}}},[(!_vm.constructorParamCollapse)?_c('c-icon',{staticClass:"mr-2 secondary-content-color cursor-pointer",attrs:{"name":"cil-plus"}}):_c('c-icon',{staticClass:"mr-2 secondary-content-color cursor-pointer",attrs:{"name":"cil-minus"}}),_c('label',{staticClass:"mb-0 fw-400 cursor-pointer"},[_vm._v(_vm._s(_vm.column.pairedConstructorName ? _vm.column.pairedConstructorName : _vm.column.displayName))])],1)])],1),_c('c-collapse',{attrs:{"show":_vm.constructorParamCollapse}},[_c('c-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('c-button',{staticClass:"btn-elevated small",on:{"click":_vm.addConstructor}},[_c('img',{staticClass:"mr-2",attrs:{"src":"/icons/add_primary_40.svg","alt":"add"}}),_vm._v(" Add ")])],1),(!_vm.isConstructorsMatched())?_c('div',{staticClass:"validation-error-message"},[_vm._v(" Constructors should be matched ")]):_vm._e()]),_vm._l((_vm.data.value),function(selectedConstructor,constructorIndex){return _c('c-row',{key:'constructor-' + constructorIndex,staticClass:"align-items-start constructor-container m-3"},[_c('c-col',{staticClass:"col-3 px-0"},[_c('div',{staticClass:"d-flex align-items-center mb-2",class:{
          'mt-30': _vm.data.value[
            constructorIndex
          ].parametersMaps.length
        }},[_c('v-select',{key:'constructors-dropdown-' + constructorIndex,staticClass:"constructors-picker",attrs:{"append-to-body":"","calculate-position":_vm.popover,"options":_vm.column.constructors,"reduce":(item) => item.name,"label":"name"},on:{"input":function($event){return _vm.onSelectedConstructorChange($event, constructorIndex)}},model:{value:(_vm.data.value[constructorIndex].name),callback:function ($$v) {_vm.$set(_vm.data.value[constructorIndex], "name", $$v)},expression:"data.value[constructorIndex].name"}}),_c('c-button',{staticClass:"min-w-unset ml-2",on:{"click":function($event){return _vm.removeConstructor(constructorIndex)}}},[_c('img',{attrs:{"src":"/icons/remove.svg"}})])],1),(!_vm.isConstructorValid(selectedConstructor))?_c('div',{staticClass:"validation-error-message position-absolute"},[_vm._v(" All fields should be filled ")]):_vm._e()]),_c('c-col',{attrs:{"col":"9"}},_vm._l((_vm.data.value[
          constructorIndex
        ].parametersMaps),function(parametersMap,parametersMapIndex){return _c('c-row',{key:'constructor-parametersMap' + parametersMapIndex,staticClass:"d-flex align-items-end"},[_vm._l((_vm.getConstructorParameters(
            _vm.data.value[constructorIndex].name
          )),function(column,parameterIndex){return [_c('common-param',{key:'constructor-' +
              constructorIndex +
              '-parameter-' +
              parameterIndex,attrs:{"isLocal":column.isLocal,"column":column,"settings":_vm.settings,"data":parametersMap[column.columnName]},on:{"receiveParamValue":function($event){return _vm.writeConstructorParamValue(
                  $event,
                  constructorIndex,
                  parametersMapIndex,
                  column.columnName
                )}}})]}),(_vm.data.value[constructorIndex].parametersMaps.length > 1)?_c('c-col',{staticClass:"mt-3 mb-1"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('c-button',{staticClass:"min-w-unset",on:{"click":function($event){return _vm.removeConstructorParametersMap(
                  constructorIndex,
                  parametersMapIndex
                )}}},[_c('img',{attrs:{"src":"/icons/remove.svg"}})])],1)]):_vm._e()],2)}),1),(_vm.column.pairedConstructorParam)?[(_vm.column.pairedConstructorParam)?_c('c-col',{staticClass:"px-0",attrs:{"col":"3"}},[_c('div',{staticClass:"d-flex align-items-center",class:{
            'mt-30': _vm.data.value[
              constructorIndex
            ].pair.parametersMaps.length
          }},[_c('v-select',{key:'constructors-dropdown-pair-' + constructorIndex,staticClass:"constructors-picker",attrs:{"append-to-body":"","calculate-position":_vm.popover,"options":_vm.column.pairedConstructorParam.constructors,"reduce":(item) => item.name,"label":"name"},on:{"input":function($event){return _vm.onSelectedPairedConstructorChange(
                  $event,
                  constructorIndex
                )}},model:{value:(_vm.data.value[constructorIndex].pair.name),callback:function ($$v) {_vm.$set(_vm.data.value[constructorIndex].pair, "name", $$v)},expression:"data.value[constructorIndex].pair.name"}})],1),(!_vm.isConstructorValid(selectedConstructor.pair))?_c('div',{staticClass:"validation-error-message position-absolute"},[_vm._v(" All fields should be filled ")]):_vm._e()]):_vm._e(),(_vm.data.value[constructorIndex].pair.name)?_c('c-col',{attrs:{"col":"9"}},_vm._l((_vm.data.value[
            constructorIndex
          ].pair.parametersMaps),function(parametersMap,parametersMapIndex){return _c('c-row',{key:'constructor-parametersMap-pair' + parametersMapIndex,staticClass:"d-flex align-items-center"},[_vm._l((_vm.getPairedConstructorParameters(
                  _vm.data.value[constructorIndex].pair.name
                )),function(column,parameterIndex){return [_c('common-param',{key:'constructor-pair-' +
                constructorIndex +
                '-parameter-' +
                parameterIndex,attrs:{"isLocal":column.isLocal,"column":column,"settings":_vm.settings,"data":parametersMap[column.columnName]},on:{"receiveParamValue":function($event){return _vm.writePairConstructorParamValue(
                    $event,
                    constructorIndex,
                    parametersMapIndex,
                    column.columnName
                  )}}})]}),(
              _vm.data.value[constructorIndex].pair.parametersMaps.length >
              1
            )?_c('c-col',{staticClass:"mt-3 mb-1"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('c-button',{staticClass:"min-w-unset",on:{"click":function($event){return _vm.removeConstructorParametersMap(
                    constructorIndex,
                    parametersMapIndex
                  )}}},[_c('img',{attrs:{"src":"/icons/remove.svg"}})])],1)]):_vm._e()],2)}),1):_vm._e()]:_vm._e()],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }