
import { Component, Prop, Vue } from "vue-property-decorator";
import { withPopper } from "@/plugins/popover";
import { IRule } from "@/interfaces/runPricingModel/rule.interface";
import GlobalRuleService from "@/services/global-rule.service";
import LocalRuleService from "@/services/local-rule.service";

@Component({})
export default class RulesList extends Vue {
  @Prop({
    required: false,
    default: () => {
      return false;
    },
  })
  isLocal: boolean;

  @Prop({
    required: false,
    default: () => {
      return [];
    },
  })
  rules: IRule[];

  public popover = withPopper;

  public ruleModalText: string = "";
  public ruleModalShow: boolean = false;

  public constructor() {
    super();
  }

  public created(): void { }

  public openRuleModal(ruleId: number): void {
    if (this.isLocal) {
      LocalRuleService.getParameters(ruleId).then((value) => {
        this.ruleModalText = value;
      });
    } else {
      GlobalRuleService.getParameters(ruleId).then((value) => {
        this.ruleModalText = value;
      });
    }
    this.ruleModalShow = true;
  }

  public closeRuleModal(): void {
    this.ruleModalShow = false;
  }
}
