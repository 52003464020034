<template>
  <div class="logo-wrapper">
    <img alt="Pricing Portal" class="logo mx-auto d-block" src="/icons/logo.svg" />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class Home extends Vue { }
</script>

<style lang="scss" scoped>
.logo-wrapper {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: 100%;
  height: 200px;
}
</style>
