import { IExecution, IRunPricingModel } from '@/interfaces/runPricingModel/runPricingModel.interface';
import { ApiBaseService } from './api-base-service';

class RunPricingService extends ApiBaseService {
    private baseEndpoint = "/runPricing";

    public runPricingModel(model: IRunPricingModel, runPricing: Boolean): Promise<any> {
        model.runPricing = runPricing;
        return this.post<any>(this.baseEndpoint, model)
    }

    public runPropensityModel(model: IRunPricingModel): Promise<any> {
        return this.post<any>(`${this.baseEndpoint}/propensity`, model)
    }

    public runExistingExecution(pricingModelExecutionId: number): Promise<any> {
        return this.post<any>(`${this.baseEndpoint}/RunByExecution`, {pricingModelExecutionId: pricingModelExecutionId })
    }

    public deleteExecution(pricingModelExecutionId: number): Promise<any> {
        return this.post<any>(`${this.baseEndpoint}/DeleteExecution`, {pricingModelExecutionId: pricingModelExecutionId })
    }

    public getExecution = (id: number): Promise<any> => this.getById<IExecution>(`${this.baseEndpoint}/execution/`, id);
}

export default new RunPricingService();