
import { Component, Vue, Emit, Prop, Watch, Ref } from 'vue-property-decorator';
import { DatePicker } from 'v-calendar';
import _ from 'lodash';

@Component({
  components: {
    'v-date-picker': DatePicker
  }
})
export default class CustomDatePicker extends Vue {
  public get masks(): any {
    return {
      title: 'MMM YYYY',
      input: 'MMMM D, YYYY',
      weekdays: 'WW'
    };
  }

  public get placeholder(): string {
    return `Choose ${this.title ? this.title : 'date'}`;
  }

  public get selectAttribute(): any {
    return {
      highlight: 'orange'
    };
  }

  public attributes: any = [
    {
      key: 'today',
      highlight: {
        color: 'purple',
        fillMode: 'outline'
      }
    }
  ];

  @Prop({ required: true }) public value: Date | string | null;
  @Prop({ required: false }) public title: string;

  public date: Date | null = null;

  public created(): void {
    if (this.value && typeof this.value === 'string') {
      this.onChange(new Date(this.value));
    }
  }

  public getUUId(): string {
    return `${_.uniqueId()}`;
  }

  @Watch('value')
  valueChanged(newValue: Date | null, oldValue: Date | null): void {
    if (newValue?.valueOf() !== oldValue?.valueOf()) {
      (this.$refs.datePicker as any).updateValue(newValue);

      if (!oldValue) {
        (this.$refs.datePickerInput as any).value = '';
      }
    }
  }

  public updateDate(date: Date | null): void {
    if (this.value?.valueOf() !== date?.valueOf()) {
      this.onChange(date);
    }
  }

  @Emit('input') public onChange(date: Date | null): Date | null {
    return date;
  }

  public togglePicker(): void {
    (this.$refs.datePicker as any).togglePopover({
      placement: 'bottom-start',
      hideDelay: 0,
      autoHide: true
    });
  }
}
