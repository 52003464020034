import { ICandidateAddress } from '@/interfaces/standardize-addresses/candidate-address.interface';
import { IStandardizeAddressCommand } from '@/interfaces/standardize-addresses/standardize-address-command.interface';
import { ApiBaseService } from './api-base-service';

class StandardizeAddressService extends ApiBaseService{
    private baseEndpoint = "/standardizeAddresses";

    public standardizeAddress = (command: IStandardizeAddressCommand): Promise<ICandidateAddress> => this.post<ICandidateAddress>(this.baseEndpoint, command, { headers: { useGlobalLoader: true, useToastNotification: false } });

    public standardizeAddressByLoanIds = (loanIds: number[]): Promise<any> => this.post(`${this.baseEndpoint}/loans`, { loanIds }, { headers: { useGlobalLoader: true, useToastNotification: false } });

    public standardizeAddressByDealId = (dealId: number): Promise<any> => this.post(`${this.baseEndpoint}/deal`, { dealId }, { headers: { useGlobalLoader: true, useToastNotification: false } });

    public getStates = (): Promise<any> => this.getWithHeaders<any>(`${this.baseEndpoint}/states`, { useGlobalLoader: true, useToastNotification: false });
}

export default new StandardizeAddressService();