
import { Component, Emit, Ref } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { BaseFormValidations } from '../base-form-validations/BaseFormValidation';
import { StandardizeAddressSummaryValidations } from '@/constants/validations/standardize-address-summary.validation';
import StandardizeAddressService from '@/services/standardize-address.service';
import DealService from '@/services/deal.service';
import { ICandidateAddress } from '@/interfaces/standardize-addresses/candidate-address.interface';
import { IDeal } from '@/interfaces/deals/deal.interface';
import { PaginatedResult } from '@/models/paginatedResult';
import { FiltrationState } from '@/models/filtrationState';
import { maxItemsInDropDown } from '@/constants/stateConstants';
import { withPopper } from '@/plugins/popover';
import StandardizedAddressResultModal from './StandardizedAddressResultModal.vue';
import { AxiosError } from 'axios';

@Component({
  mixins: [validationMixin],
  validations: StandardizeAddressSummaryValidations,
  components: {
    'standardized-address-result-modal': StandardizedAddressResultModal
  }
})
export default class StandardizeAddressModal extends BaseFormValidations<any> {
  public form = this.getEmptyForm();
  public deals: IDeal[] = [];
  public states: string[] = [];
  public isStandardizedAddressModalShow = false;
  public standardizationAddressErrorModal: any = {
    show: false,
    message: null
  };
  public popover = withPopper;

  public standardizedAddress: ICandidateAddress | null = null;
  public numberOfItemsLoaded: number = 0;
  public isLoadMore: boolean = false;
  public search: string | null = null;

  protected getEmptyForm(): any {
    return {
      address: null,
      city: null,
      state: null,
      zipcode: null,
      dealId: null,
      loanIds: null,
      activeTab: 0
    };
  }

  public mounted(): void {
    this.loadStates();
  }

  @Emit('submit')
  public onSubmit(): void {
    return;
  }

  @Emit('close')
  public close(): void {
    return;
  }

  public standardizedAddressModalClose(): void {
    this.standardizedAddress = null;
    this.isStandardizedAddressModalShow = false;
  }

  public onTabChange(tabIndex: number): void {
    if (tabIndex === 2) {
      this.searchDeals('', true, 0, false);
    }

    this.reset();
  }

  public reset(): void {
    this.form = this.getEmptyForm();
    this.$v.$reset();
  }

  public submit(): void {
    this.validate();
    if (this.isValid) {
      switch (this.form.activeTab) {
        case 0: {
          StandardizeAddressService.standardizeAddress({
            address: this.form.address,
            city: this.form.city,
            state: this.form.state,
            zipcode: this.form.zipcode
          })
            .then((resp: ICandidateAddress) => {
              this.reset();
              this.onSubmit();

              this.standardizedAddress = resp;
              this.isStandardizedAddressModalShow = true;
            })
            .catch((error: AxiosError<any>) => {
              this.standardizedAddress = null;
              this.isStandardizedAddressModalShow = false;
              this.standardizationAddressErrorModal.show = true;
              this.standardizationAddressErrorModal.message =
                error.response?.data.detail;
            });
          break;
        }
        case 1: {
          StandardizeAddressService.standardizeAddressByLoanIds(
            this.form.loanIds.split(',')
          ).then(() => {
            this.reset();
            this.onSubmit();
          });
          break;
        }
        case 2: {
          StandardizeAddressService.standardizeAddressByDealId(
            this.form.dealId
          ).then(() => {
            this.reset();
            this.onSubmit();
          });
          break;
        }
      }
    }
  }

  public loadMore(): void {
    this.searchDeals(this.search, true, this.numberOfItemsLoaded, true);
  }

  public searchDeals(
    search: string | null,
    loading: boolean,
    skipCount: number,
    isLoadMore: boolean
  ): void {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown
    };

    if (search !== this.search) {
      this.numberOfItemsLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: 'or',
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: search,
            ignoreCase: true
          }
        ]
      };
    }

    this.loadDeals(state, isLoadMore);
  }

  private loadDeals(state: FiltrationState, isLoadMore: boolean): void {
    DealService.getDeals(state).then((resp: PaginatedResult<IDeal>) => {
      if (isLoadMore) {
        for (const deal of resp.entities) {
          this.deals.push(deal);
        }
      } else {
        this.deals = resp.entities;
      }

      this.numberOfItemsLoaded += resp.entities.length;
      if (resp.totalCount > this.numberOfItemsLoaded) {
        this.isLoadMore = true;
      } else {
        this.isLoadMore = false;
        this.numberOfItemsLoaded = 0;
      }
    });
  }

  private loadStates(): void {
    StandardizeAddressService.getStates().then((resp: string[]) => {
      this.states = resp;
    });
  }
}
