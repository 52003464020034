var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('c-row',{staticClass:"d-flex align-content-center row-height align-items-center"},[_c('c-col',{staticClass:"d-flex align-items-center justify-content-start flex-wrap",attrs:{"col":"10"}},[_c('div',{staticClass:"d-flex col-lg-5 pl-0"},[_c('label',{staticClass:"mb-0 fw-400 fs-16"},[_vm._v(_vm._s(_vm.column.displayName))]),_c('div',{staticClass:"tooltip-md d-inline-flex ml-2 cursor-pointer"},[_c('img',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.column.explanation,
            placement: 'left',
            boundaries: 'viewport',
          }),expression:"{\n            content: column.explanation,\n            placement: 'left',\n            boundaries: 'viewport',\n          }"}],attrs:{"src":"/icons/tooltip.svg"}})])]),(!_vm.data.isOperation && !_vm.isOperationSelected && _vm.vectorParamResult.length > 0)?_c('div',{staticClass:"flex-grow-1 secondary-content-color d-flex justify-content-start col-lg-7"},[_vm._v(" [ "),_c('span',{staticClass:"primary-content-color text-truncate"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.vectorParamResult.join(", "),180)))]),_vm._v(" ] ")]):_vm._e()]),_c('c-col',{staticClass:"ml-auto",attrs:{"col":"2"}},[_c('c-row',[_c('c-col',{attrs:{"col":"12 d-flex justify-content-end"}},[(!_vm.data.isOperation && !_vm.isOperationSelected)?_c('div',{staticClass:"d-inline-flex align-items-center"},[(_vm.vectorParamResult.length)?_c('img',{staticClass:"cursor-pointer mr-2",attrs:{"src":"/icons/remove_red_eye.svg"},on:{"click":function($event){return _vm.openVectorModal()}}}):_vm._e()]):_vm._e(),_c('div',[(!_vm.data.isOperation && !_vm.isOperationSelected)?[(!_vm.vectorParamResult.length)?_c('c-button',{key:"build-vector",staticClass:"custom-btn mr-2",on:{"click":_vm.openSegmentsModal}},[_c('img',{attrs:{"src":"/icons/settings.svg"}})]):_vm._e(),(_vm.data.value.segments && _vm.vectorParamResult.length)?_c('c-button',{key:"build-vector",staticClass:"custom-btn mr-2",on:{"click":_vm.openSegmentsModal}},[_c('img',{attrs:{"src":"/icons/edit.svg"}})]):_vm._e(),(_vm.data.value.rangeValue && _vm.vectorParamResult.length)?_c('c-button',{key:"build-vector",staticClass:"custom-btn mr-2",on:{"click":_vm.openImportModal}},[_c('img',{attrs:{"src":"/icons/edit.svg"}})]):_vm._e()]:_vm._e(),(
              !_vm.data.isOperation &&
              !_vm.isOperationSelected &&
              !_vm.vectorParamResult.length
            )?[(!_vm.data.value.segments || _vm.data.value.rangeValue)?_c('c-button',{key:"add-vector",staticClass:"custom-btn mr-2",on:{"click":_vm.openImportModal}},[_c('img',{attrs:{"src":"/icons/upload.svg"}})]):(_vm.data.value.segments && !_vm.data.value.rangeValue)?_c('c-button',{directives:[{name:"confirm-operation",rawName:"v-confirm-operation",value:({
                  operation: 'reset',
                  useCustomMessage: true,
                  customMessage: `Vector data will be deleted`,
                  submitButtonText: 'Continue',
                  cancelButtonText: 'Cancel',
                }),expression:"{\n                  operation: 'reset',\n                  useCustomMessage: true,\n                  customMessage: `Vector data will be deleted`,\n                  submitButtonText: 'Continue',\n                  cancelButtonText: 'Cancel',\n                }"}],key:"add-vector-with-confirm",staticClass:"custom-btn mr-2",on:{"confirm-operation-submit":_vm.openImportModal}},[_c('img',{attrs:{"src":"/icons/upload.svg"}})]):_vm._e()]:_vm._e(),(!_vm.data.isOperation && !_vm.isOperationSelected)?[(_vm.data.value.segments || _vm.data.value.rangeValue)?_c('img',{directives:[{name:"confirm-operation",rawName:"v-confirm-operation",value:({
                  operation: 'delete',
                  useCustomMessage: true,
                  customMessage: `Vector data will be deleted`,
                  submitButtonText: 'Continue',
                  cancelButtonText: 'Cancel',
                }),expression:"{\n                  operation: 'delete',\n                  useCustomMessage: true,\n                  customMessage: `Vector data will be deleted`,\n                  submitButtonText: 'Continue',\n                  cancelButtonText: 'Cancel',\n                }"}],staticClass:"cursor-pointer ml-2",attrs:{"src":"/icons/remove.svg"},on:{"confirm-operation-submit":_vm.deleteVector}}):_vm._e()]:_vm._e()],2)])],1)],1)],1),_c('div',{staticClass:"operations-block"},[(_vm.data.isOperation)?_c('v-select',{key:"ops",staticClass:"operations-picker",attrs:{"append-to-body":"","calculate-position":_vm.popover,"options":_vm.operationTypes,"reduce":(item) => item.value,"label":"name"},model:{value:(_vm.data.operationType),callback:function ($$v) {_vm.$set(_vm.data, "operationType", $$v)},expression:"data.operationType"}}):_vm._e(),(_vm.data.isOperation)?_c('c-input',{attrs:{"lazy":false,"value":_vm.data.operationValue,"placeholder":"0.00"},on:{"update:value":function($event){return _vm.$set(_vm.data, "operationValue", $event)},"change":function($event){return _vm.onOperationValueChange()}}}):_vm._e(),(_vm.isOperationSelected)?_c('c-input',{attrs:{"lazy":false,"value":_vm.data.value,"disabled":""},on:{"update:value":function($event){return _vm.$set(_vm.data, "value", $event)}}}):_vm._e()],1),(_vm.vectorModalOptions.show && _vm.isDataAvailable)?_c('vector-modal',{attrs:{"show":_vm.vectorModalOptions.show && _vm.isDataAvailable,"chart-data":_vm.chartData,"is-data-available":_vm.isDataAvailable},on:{"close":_vm.closeVectorModal,"chart-data:update":_vm.onChartDataUpdate}}):_vm._e(),(_vm.segmentsModalOptions.show)?_c('vector-param-segments-modal',{attrs:{"segments":_vm.segmentsModalOptions.segments},on:{"close":_vm.closeSegmentsModal,"submit":_vm.saveVector}}):_vm._e(),(_vm.importModalOptions.show)?_c('vector-param-import-modal',{attrs:{"range":_vm.importModalOptions.range},on:{"close":_vm.closeImportModal,"submit":_vm.saveVector}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }