var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('c-row',{staticClass:"d-flex align-content-center"},[_c('c-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-3",on:{"click":function($event){_vm.tableParamCollapse = !_vm.tableParamCollapse}}},[(!_vm.tableParamCollapse)?_c('c-icon',{staticClass:"mr-2 secondary-content-color cursor-pointer",attrs:{"name":"cil-plus"}}):_c('c-icon',{staticClass:"mr-2 secondary-content-color cursor-pointer",attrs:{"name":"cil-minus"}}),_c('label',{staticClass:"mb-0 fw-400 cursor-pointer"},[_vm._v(_vm._s(_vm.column.displayName))])],1)])],1),_c('c-collapse',{attrs:{"show":_vm.tableParamCollapse}},[_c('c-row',[_c('c-col',{attrs:{"col":"12 mt-4"}},[_c('table',{staticClass:"table table-dark"},[_c('thead',[_c('tr',_vm._l((_vm.column.tableColumns),function(column,index){return _c('th',{key:'col_' + index,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(column.displayName)+" ")])}),0)]),_c('tbody',[(_vm.data.value)?_c('tr',_vm._l((_vm.column.tableColumns),function(column,index){return _c('td',{key:'row_' + index,attrs:{"scope":"row"}},[(
                  _vm.data.value[index] &&
                  column.dataType == _vm.runPricingDataTypes.LIST_INT
                )?_c('c-input',{staticClass:"mb-0 small",attrs:{"lazy":false,"value":_vm.data.value[index].value,"placeholder":"1,2...","isValid":_vm.isTableValid,"invalidFeedback":_vm.errorMessage},on:{"update:value":function($event){return _vm.$set(_vm.data.value[index], "value", $event)},"blur":function($event){return _vm.update()}}}):_vm._e(),(
                  _vm.data.value[index] &&
                  column.dataType == _vm.runPricingDataTypes.LIST_FLOAT
                )?_c('c-input',{staticClass:"mb-0 small",attrs:{"lazy":false,"value":_vm.data.value[index].value,"placeholder":"1.01,2.02...","isValid":_vm.isTableValid,"invalidFeedback":_vm.errorMessage},on:{"update:value":function($event){return _vm.$set(_vm.data.value[index], "value", $event)},"blur":function($event){return _vm.update()}}}):_vm._e()],1)}),0):_vm._e()])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }