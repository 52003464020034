
import { IDeal } from '@/interfaces/deals/deal.interface';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DealSummaryValidations } from '@/constants/validations/deal-summary.validation';
import { validationMixin } from 'vuelidate';
import { IImportTemplate } from '@/interfaces/importTemplates/import-template.interface';
import { getModule } from 'vuex-module-decorators';
import ImportTemplateModule from '@/store/modules/import-templates.module';
import EntityDataModule from '@/store/modules/entity-data.module';
import { BaseFormValidations } from '../base-form-validations/BaseFormValidation';
import DealService from '@/services/deal.service';
import { withPopper } from '@/plugins/popover';
import { ILoanRawPropertyMetadata } from '@/interfaces/loanRaws/loan-raw-property-metadata.interface';
import _ from 'lodash';

@Component({
  mixins: [validationMixin],
  validations: DealSummaryValidations
})
export default class DealSummary extends BaseFormValidations<IDeal> {
  public get templateOptions(): any[] {
    return [
      ...this.templates.map((it: IImportTemplate) => {
        return {
          value: it.id,
          label: it.name
        };
      })
    ];
  }

  public get keyOptions(): any[] {
    return [
      ...this.loanProperties.map((o: ILoanRawPropertyMetadata) => {
        return {
          value: o.name,
          label: o.name
        };
      })
    ];
  }

  public get depositAmountRequiredLabel(): string {
    return `Deposit amount required ($) ${
      this.$v.form.depositAmountRequired?.$invalid ? '*' : ''
    }`;
  }

  // Props
  @Prop({ required: true, default: null }) importFile: File | null;

  // data
  public selectedImportTemplateId: number | null = null;
  public isDealDataLoading: boolean = false;
  public isVaitingForDataLoad: boolean = false;
  public form = this.getEmptyForm();
  public importedFile: File | null = null;
  public competitiveNegotiatedOptions = [
    { value: 'competitive', label: 'Competitive' },
    { value: 'negotiated', label: 'Negotiated' }
  ];
  public popover = withPopper;

  private templates: IImportTemplate[] = [];
  private loanProperties: ILoanRawPropertyMetadata[] = [];
  private importTemplateModule: ImportTemplateModule;
  private entityDataModule: EntityDataModule;

  public constructor() {
    super();

    this.importTemplateModule = getModule(ImportTemplateModule);
    this.entityDataModule = getModule(EntityDataModule);
  }

  protected getEmptyForm(): IDeal {
    return {
      name: '',
      revisionName: '',
      sellerName: '',
      saleManagedBy: '',
      collateralType: '',
      competitiveNegotiated: 'competitive',
      bidDate: null,
      cutoffDate: null,
      settleDate: null,
      isDepositRequired: undefined,
      depositAmountRequired: undefined,
      key: null
    };
  }

  // emits
  @Emit('upload-import-file')
  public onFileUpload(file: File | null): File | null {
    return file;
  }

  @Emit('map-columns')
  public onSubmit(): IDeal {
    return this.form;
  }

  @Emit('set-template')
  public onTemplateChange(): any | null {
    if (this.selectedImportTemplateId !== null) {
      return this.templateOptions.find(
        (t) => t.value === this.selectedImportTemplateId
      );
    }
    return null;
  }

  // methods
  public uploadFile(file: File | null): void {
    this.importedFile = file;
    this.onFileUpload(file);
  }

  public created(): void {
    this.loadImportTemplates();
    this.loadLoanRawMetadata();
  }

  public submit(): void {
    if (this.isDealDataLoading) {
      this.isVaitingForDataLoad = true;
      return;
    }
    this.validate();

    if (!this.importedFile) {
      Vue.$toast.clear();
      Vue.$toast.error('Import file is required');
    }

    if (this.isValid && this.importedFile) {
      this.onTemplateChange();
      this.onSubmit();
    }
  }

  public loadImportTemplates(): void {
    this.importTemplateModule.getImportTemplates().then((templates) => {
      this.templates = templates.sort((item1, item2) => {
        if (item1.name < item2.name) {
          return -1;
        }
        if (item1.name > item2.name) {
          return 1;
        }
        return 0;
      });
    });
  }

  public loadLoanRawMetadata(): void {
    this.entityDataModule
      .getLoanRawMetadata()
      .then((propertiesMetadata: ILoanRawPropertyMetadata[]) => {
        this.loanProperties = _.orderBy(propertiesMetadata, ['name']);
      });
  }

  public loadDealByNameIfExist(): void {
    if (this.form.name) {
      this.isDealDataLoading = true;
      DealService.getExistDealOrDefault(this.form.name)
        .then((deal) => {
          if (deal) {
            this.form = {
              id: deal.id,
              name: deal.name,
              revisionName: deal.revisionName,
              key: deal.key,
              sellerName: deal.sellerName,
              saleManagedBy: deal.saleManagedBy,
              collateralType: deal.collateralType,
              competitiveNegotiated: deal.competitiveNegotiated,
              bidDate: deal.bidDate != null ? new Date(deal.bidDate) : null,
              settleDate:
                deal.settleDate != null ? new Date(deal.settleDate) : null,
              cutoffDate:
                deal.cutoffDate != null ? new Date(deal.cutoffDate) : null,
              isDepositRequired: deal.isDepositRequired,
              depositAmountRequired: deal.depositAmountRequired
            };
          }
        })
        .finally(() => {
          this.isDealDataLoading = false;
          if (this.isVaitingForDataLoad) {
            this.isVaitingForDataLoad = false;
            this.submit();
          }
        });
    }
  }

  public reset(): void {
    this.form = this.getEmptyForm();
    this.$v.$reset();
    this.selectedImportTemplateId = null;
  }
}
