
import LoaderModule from '@/store/modules/loader.module';
import { Component, Vue, Emit, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component({})
export default class Loader extends Vue {
  private loaderModule: LoaderModule = getModule(LoaderModule);

  public get isVisible(): boolean {
    document.body.style.overflow = this.loaderModule.loading > 0 ? 'hidden' : 'auto';
    return this.loaderModule.loading > 0;
  }
}
