
import { Component, Vue, Watch } from "vue-property-decorator";
import { withPopper } from "@/plugins/popover";
import { validationMixin } from "vuelidate";
import { ParameterAdjustmentValidations } from "@/constants/validations/parameter-adjustment.validation";
import { checkIfValid } from "@/utils/validators";
import { IParameterAdjustmentDetails } from "@/interfaces/runPricingModel/parameterAdjustmentDetails.interface";
import ParameterAdjustmentService from "@/services/parameter-adjustment.service";
import DynamicParams from "../../components/run-pricing-model/parameters/DynamicParams.vue";
import { getModule } from "vuex-module-decorators";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { operationTypes } from "@/components/run-pricing-model/parameters/constants/operationTypes.const";
import {
  IRunPricingCategory,
  IRunPricingColumn,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import { ILocalRuleDetails } from "@/interfaces/runPricingModel/localRuleDetails.interface";
import { Dictionary } from "vue-router/types/router";
import {
  isFloat,
  isRunPricingParameterEmpty,
  isRunPricingParameterValid
} from "@/constants/validations/run-pricing-model-parameters.validation";
import {
  subsetRuleYtmSpreadColumns,
  subsetRuleYtmSpreadRows,
} from "@/constants/subsetRuleYtmSpread.const";
import {
  IYtmSpreadColumn,
  IYtmSpreadData,
  IYtmSpreadRow,
} from "@/interfaces/runPricingModel/subsetRuleDetails.interface";
import { ParametersService } from "@/services/parameters.service";
import _ from "lodash";
import { validPromise } from "@/utils/validPromise";
import { RPMPagesType } from "@/enums/rpm-pages-type";

@Component({
  mixins: [validationMixin],
  validations: ParameterAdjustmentValidations,
  methods: {
    isFloat,
    updateName: function (this: any, e: any) {
      this.form.name = e;
      this.$v.form.$touch();
    },
  },
  components: {
    "dynamic-params": DynamicParams,
  },
  data() {
    return {
      isRouteLeaving: false,
      isRedirectToPrevious: false,
      nextRouteName: String,
      nextRoute: Function,
      parametersService: ParametersService,
    };
  },
  async beforeRouteLeave(to, from, next) {
    this.$data.nextRouteName = to.name;
    this.$data.form.parameters =
      this.$data.parametersService.FormatDateParameters(
        this.$data.form.parameters,
        "LL",
        false
      );
    this.ytmSpreadTableChanged(this.form, this.ytmSpreadTable);
    await this.formChanged();
    this.$data.isRouteLeaving = this.isDirty;

    if (this.$data.isRedirectToPrevious) {
      this.$data.isRouteLeaving = false;
    }

    if (!this.$data.isRouteLeaving) {
      next();
    }

    this.$data.isRedirectToPrevious = false;
    this.$data.nextRoute = next;
  },
})
export default class ParameterAdjustment extends Vue {
  // properties
  public popover = withPopper;
  public isSaveStateAfterSubmit: boolean = false;
  public categories: IRunPricingCategory[] | null;
  public ytmRows = subsetRuleYtmSpreadRows;
  public ytmColumns = subsetRuleYtmSpreadColumns;
  public ytmSpreadTable: IYtmSpreadRow[] = [];
  public ytmSpreadTablePristine: IYtmSpreadRow[] = [];
  public parentPath: string = "Scenario";

  protected form: IParameterAdjustmentDetails = {
    id: undefined,
    name: "",
    parameters: [],
    settings: {
      isOperationsEnable: false,
    },
    ytmSpreadData: null,
  };

  protected pristineForm: IParameterAdjustmentDetails = {
    id: undefined,
    name: "",
    parameters: [],
    settings: {
      isOperationsEnable: false,
    },
    ytmSpreadData: null,
  };

  public isInvalid: boolean = false;
  private runPricingModelModule: RunPricingModelModule;
  public isParameterNeeded: boolean = false;
  public isDirty: boolean = false;

  public constructor() {
    super();
    this.runPricingModelModule = getModule(RunPricingModelModule);
    this.categories =
      this.runPricingModelModule.runPricingParametersInfoState?.categories ??
      null;
    this.form.parameters.map((param) => {
      param.inputValue = 10;
    });
  }

  public created(): void {
    if (!this.categories) {
      this.runPricingModelModule.getRunPricingParametersInfo().then((value) => {
        this.categories = value.categories;
      });
    }
    if (this.$route.params.from) {
      this.parentPath = this.$route.params.from;
    }
    this.loadParameterAdjustmentModelState();
  }

  protected getEmptyForm(): IParameterAdjustmentDetails {
    return {
      id: undefined,
      name: "",
      parameters: [],
      settings: {
        isOperationsEnable: false,
      },
      ytmSpreadData: null,
    };
  }

  private async loadParameterAdjustmentModelState(): Promise<void> {
    const lastParameterAdjastment: IParameterAdjustmentDetails | null =
      this.getLastParameterAdjustmentFromStore();
    if (
      (lastParameterAdjastment?.id || this.$route.params.id) &&
      !(
        lastParameterAdjastment &&
        +this.$route.params.id === lastParameterAdjastment?.id
      )
    ) {
      this.loadParameterAdjustment(
        +this.$route.params.id
          ? +this.$route.params.id
          : <number>lastParameterAdjastment?.id
      );
    } else {
      if (
        lastParameterAdjastment &&
        (+this.$route.params.id === lastParameterAdjastment?.id ||
          !lastParameterAdjastment?.id)
      ) {
        lastParameterAdjastment.parameters =
          ParametersService.FormatDateParameters(
            lastParameterAdjastment.parameters
          );
        this.pristineForm = JSON.parse(JSON.stringify(lastParameterAdjastment));
        this.form = lastParameterAdjastment;
      } else {
        this.pristineForm = this.getEmptyForm();
        this.form = this.getEmptyForm();
      }
      this.setYtmSpreadTable(null);
    }

    this.ytmSpreadTableChanged(this.pristineForm, this.ytmSpreadTablePristine);
    await validPromise.call(this, "form");
  }

  private getLastParameterAdjustmentFromStore(): IParameterAdjustmentDetails | null {
    return <IParameterAdjustmentDetails>(
      this.runPricingModelModule?.runPricingModelState?.parameterAdjustment
    );
  }

  @Watch("form", { deep: true })
  public async formChanged() {
    this.isDirty =
      await this.runPricingModelModule.checkFormIsDirty({
        form: this.form,
        pristineForm: this.pristineForm,
        type: RPMPagesType.ParameterAdjustment
      });
  }

  public isControlValid(fieldName: string): boolean {
    return checkIfValid(this.$v.form, fieldName);
  }

  public validate(): void {
    this.$v.$touch();
    this.$v.form.$touch();
  }

  public isParamsEmpty(): boolean {
    const columns: IRunPricingColumn[] | undefined = this.categories?.flatMap(
      (c) => c.columns
    );
    let isEmpty = true;
    this.ytmSpreadTable.forEach((row) => {
      row.columns.forEach((column: IYtmSpreadColumn) => {
        if (column.value || column.value === 0) {
          isEmpty = false;
        }
      });
    });
    if (!isEmpty) {
      return isEmpty;
    }
    return columns
      ? !this.form.parameters.some(
        (value) => !isRunPricingParameterEmpty(value, columns)
      )
      : true;
  }

  public loadParameterAdjustment(id: number): void {
    ParameterAdjustmentService.getParameterAdjustment(id).then((value) => {
      value.parameters = ParametersService.FormatDateParameters(
        value.parameters
      );
      value.parameters.map((val) => {
        val.inputValue = !val.isOperation ? val.value : null;
      });
      this.pristineForm = JSON.parse(JSON.stringify(value));
      this.form = value;

      if (!this.form.settings) {
        this.form.settings = {
          isOperationsEnable: false,
        };
        this.pristineForm.settings = {
          isOperationsEnable: false,
        };
      }
      this.setYtmSpreadTable(this.form.ytmSpreadData);
      this.ytmSpreadTableChanged(
        this.pristineForm,
        this.ytmSpreadTablePristine
      );
    });
  }

  public ytmSpreadTableChanged(
    formToChange: IParameterAdjustmentDetails,
    tableToChange: IYtmSpreadRow[]
  ): void {
    let ytmSpreadData: IYtmSpreadData[] = [];
    tableToChange.forEach((row: { columns: IYtmSpreadColumn[]; name: any }) => {
      row.columns.forEach((column: IYtmSpreadColumn) => {
        if (column.value || column.value === 0) {
          ytmSpreadData.push({
            rowName: row.name,
            columnName: column.name,
            value: column.value,
          });
        }
      });
    });

    formToChange.ytmSpreadData = ytmSpreadData;
  }

  public async submit(): Promise<void> {
    this.validate();
    if (this.$v.form.$pending) {
      await validPromise.call(this, 'form');
    }
    this.isInvalid = this.isParamsInvalid();
    this.isParameterNeeded = this.isParamsEmpty();
    if (this.isParameterNeeded) {
      this.$data.isRouteLeaving = false;
      return;
    }
    if (this.isInvalid || this.$v.form.$invalid) {
      this.isInvalid = true;
      this.$data.isRouteLeaving = false;
      return;
    } else {
      this.ytmSpreadTableChanged(this.form, this.ytmSpreadTable);
      if (
        this.form.ytmSpreadData?.findIndex((val: { value: any }) => {
          return !isFloat(val.value);
        }) != -1
      ) {
        this.$data.isRouteLeaving = false;
        return;
      }
      this.form.parameters.map((param) => {
        param.value = param.isOperation
          ? operationTypes.find((val) => val.value === param.operationType)
            ? operationTypes.find((val) => val.value === param.operationType)
              .name
            : "" + param.operationValue
          : param.inputValue;
        param.operationType = param.isOperation ? param.operationType : null;
        param.operationValue = param.isOperation ? param.operationValue : null;
      });
      this.form.id ? this.update() : this.create();
    }
  }

  public create(): void {
    ParameterAdjustmentService.createParameterAdjustment(this.form).then((ruleId: number) => {
      this.form.id = ruleId;
      Vue.$toast.clear();
      Vue.$toast.success(`Parameter Adjustments ${this.form.name} created`);
      this.$data.isRedirectToPrevious = true;
      this.returnToLocalRule({ redirectToPrevious: true });
    });
  }

  public update(): void {
    ParameterAdjustmentService.updateParameterAdjustment(this.form.id as number, this.form).then(
      () => {
        Vue.$toast.clear();
        Vue.$toast.success(`Parameter Adjustments ${this.form.name} updated`);
        this.$data.isRedirectToPrevious = true;
        this.returnToLocalRule({ redirectToPrevious: true });
      }
    );
  }

  public saveParamsData(data: IParamData[]): void {
    this.form.parameters = data;
  }

  public returnToLocalRule({
    item = "LocalRule",
    save = true,
    redirectToPrevious = false,
  } = {}): void {
    let routerParams: Dictionary<string> | undefined;

    if (
      this.runPricingModelModule.runPricingModelState &&
      this.runPricingModelModule.runPricingModelState.scenario
    ) {
      const lastIndex: number =
        this.runPricingModelModule.runPricingModelState.scenario
          .localRules.length - 1;
      const lastLocalRule: ILocalRuleDetails | null =
        this.runPricingModelModule.runPricingModelState.localRule;

      if (lastLocalRule?.id) {
        routerParams = {
          id: lastLocalRule.id.toString(),
        };
      }
    }

    if (save && this.isSaveStateAfterSubmit) {
      this.saveState();
      this.isSaveStateAfterSubmit = false;
    } else {
      let state = this.runPricingModelModule.runPricingModelState;
      if (state) {
        state.parameterAdjustment = this.getEmptyForm();
        if (state.localRule) {
          state.localRule.parameterAdjustmentId = this.form.id
            ? this.form.id
            : null;
        }
        this.runPricingModelModule.updateState(state);
      }
    }

    if (this.$data.nextRoute && !redirectToPrevious) {
      this.$data.nextRoute();
      return;
    }

    this.$router.push({
      name:
        typeof this.$data.nextRouteName === "string"
          ? this.$data.nextRouteName
          : item,
      params: routerParams,
    });
  }

  public isParamsInvalid(): boolean {
    const columns: IRunPricingColumn[] | undefined = this.categories?.flatMap(
      (c) => c.columns
    );
    return columns
      ? this.form.parameters.some(
        (value) => !isRunPricingParameterValid(value, columns)
      )
      : false;
  }

  public onHideOrShowOperations(value: boolean): void {
    if (!value) {
      const columns: IRunPricingColumn[] | undefined = this.categories?.flatMap(
        (c) => c.columns
      );
      this.form.parameters.map((param) => {
        if (!param.isOperation && !param.inputValue)
          param.inputValue = columns?.find(
            (c) => c.columnName == param.columnName
          )?.defaultValue;
        param.isOperation = false;
        param.isOperationToggleOn = false;
      });
    } else {
      this.form.parameters.map((param) => {
        param.isOperation = param.isOperationToggleOn;
      });
    }
  }

  private saveState(): void {
    let state = this.runPricingModelModule.runPricingModelState;
    if (state) {
      state.parameterAdjustment = this.form;
      if (state.localRule) {
        state.localRule.parameterAdjustmentId = this.form.id;
      }
      this.runPricingModelModule.updateState(state);
    }
  }

  private setYtmSpreadTable(data: IYtmSpreadData[] | null): void {
    subsetRuleYtmSpreadRows.forEach((row) => {
      this.ytmSpreadTable.push({
        name: row,
        columns: subsetRuleYtmSpreadColumns.map((column) => {
          return {
            name: column,
            value:
              data?.find((val) => {
                return val.rowName == row && val.columnName == column;
              })?.value ?? null,
          };
        }),
      });
      this.ytmSpreadTablePristine = JSON.parse(
        JSON.stringify(this.ytmSpreadTable)
      );
    });
  }
}
