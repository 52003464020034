
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Chart, ChartDataSets } from "chart.js";
import "chartjs-plugin-dragdata";

@Component({})
export default class VectorLineChart extends Vue {
  @Prop({ required: true, default: {} })
  public chartData: any;

  @Prop({ required: false, default: undefined })
  public chartOptions!: any;

  @Ref() public readonly lineChartCanvas: any;
  @Ref() public readonly lineChartAxis: any;

  private options: any = {};
  private chart: Chart;
  private activePoints: {}[] | null;

  private readonly beforeDrawPlugin: any = {
    beforeDraw: (chart: any) => {
      const maxYAxisRange: number = this.getMaxValueInDatasets();

      chart.$zoom._options.zoom.rangeMax = { y: maxYAxisRange + 1 };
    },
  };

  @Emit("chart-data:update")
  public onChartDataUpdate(): any {
    return this.chart.data;
  }

  @Watch("chartData", { deep: true })
  public onDatasetChanged(newValue: any): void {
    this.chart.clear();
    this.chart.data = newValue;
    this.resetZoom();
  }

  public resetZoom(): void {
    (this.chart as any).resetZoom();
  }

  public mounted(): void {
    // tslint:disable-next-line:no-this-assignment
    const self = this;

    if (!self.chartOptions) {
      self.applyDefaultOptions();
    }

    self.chart = new Chart(self.lineChartCanvas, {
      type: "line",
      data: self.chartData,
      options: self.options,
      plugins: [self.beforeDrawPlugin],
    });

    self.lineChartCanvas.onmousemove = (evt: any) => {
      self.activePoints = self.chart.getElementsAtEvent(evt);
    };
  }

  private getMaxValueInDatasets(): number {
    let maxYAxisRange: number = 0;
    if (this.chart && this.chart.data && this.chart.data.datasets) {
      const maxValuesInDatasets: number[] = this.chart.data.datasets.map(
        (dataset: ChartDataSets) => {
          const data: number[] = (dataset.data! as number[]).filter(
            (i: any) => !isNaN(i)
          );
          return Math.max(...data);
        }
      );

      maxYAxisRange = Math.round(Math.max(...maxValuesInDatasets));
    }

    return maxYAxisRange;
  }

  private applyDefaultOptions(): void {
    this.options = {
      dragData: true,
      dragX: true,
      dragDataRound: 0,
      dragOptions: {
        showTooltip: true,
      },
      onDrag: this.onDragChartEvent,
      onDragEnd: this.onDragEndChartEvent,
      tooltips: {
        custom: (tooltipModel: any) => {
          if (tooltipModel.body && tooltipModel.body.length > 1) {
            tooltipModel.body = [tooltipModel.body[0]];
          }
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      legend: {
        display: false,
      },
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
        zoom: this.getZoomSettings(),
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        x: {
          type: 'category'
        },
        xAxes: [
          {
            gridLines: {
              display: true,
              drawOnChartArea: false,
              drawTicks: true,
              drawBorder: false,
              color: "#323F66",
            },
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              fontColor: "#ffffff",
              padding: 10,
              stepSize: 1,
              autoSkip: true,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "#323F66",
            },
            display: true,
            position: "right",
            ticks: {
              beginAtZero: true,
              fontSize: 15,
              fontColor: "lightgrey",
              padding: 10,
              stepSize: 1,
              autoSkip: true,
              maxTicksLimit: 10,
              callback: (value: number) => {
                if (Math.floor(value) === value) {
                  return value;
                }
              },
            },
          },
        ],
      },
    };
  }

  private onDragEndChartEvent(e: any): void {
    e.target.style.cursor = "default";
    this.onChartDataUpdate();
  }

  private onDragChartEvent(e: any): any {
    if (this.chart!.data!.datasets!.length !== 1) {
      e.target.style.cursor = "not-allowed";
      return false;
    } else {
      e.target.style.cursor = "grabbing";
    }
  }

  private getZoomSettings(): any {
    // tslint:disable-next-line:no-this-assignment
    const self = this;
    return {
      pan: {
        enabled: true,
        mode: (e: any) => {
          if (!(this.activePoints && this.activePoints.length)) {
            return "xy";
          }

          return "";
        },
        modifierKey: "ctrl",
        rangeMin: { y: null, x: 0 },
      },
      onPan: (e: any) => {
        const maxYAxisRange: number = self.getMaxValueInDatasets();
        const yAxiosScale: number = e.chart.scales["y-axis-0"].min;
        if (
          !e.chart.$zoom._options.pan.rangeMax &&
          yAxiosScale > maxYAxisRange
        ) {
          e.chart.$zoom._options.pan.rangeMax = {
            y: Math.floor(e.chart.scales["y-axis-0"].max),
            x: 0,
          };
        } else {
          e.chart.$zoom._options.pan.rangeMax = null;
          e.chart.$zoom._options.pan.rangeMin = null;
        }
      },
      zoom: {
        enabled: true,
        mode: "xy",
        speed: 0.1,
        sensitivity: 0.1,
        rangeMin: { y: null },
        rangeMax: { y: null },
        pinch: {
          enabled: true,
        },
      },
    };
  }
}
