
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { JsonQueryRuleModel } from "@/components/json-query-builder/models/JsonQueryRuleModel";
import _ from "lodash";
import { JsonQueryConfigModel } from "./models/JsonQueryConfigModel";
import JsonQueryGroup from "./JsonQueryGroup.vue";
import { withPopper } from "@/plugins/popover";
import { JsonQueryRuleOperator } from "./enums/JsonQueryRuleOperator";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { checkIfValid } from "@/utils/validators";
import { RunPricingDataType } from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";

@Component({
  mixins: [validationMixin],
  validations: {
    rule: {
      field: {
        required,
      },
      operator: {
        required,
      },
      value: {
        required: requiredIf((value) =>
        (value.operator === JsonQueryRuleOperator.WithinRange ||
          value.operator === JsonQueryRuleOperator.ExcludeRange ||
          value.operator === JsonQueryRuleOperator.AllNull))
      },
      type: {}
    },
    rangeForm: {
      leftValue: {
        required
      },
      leftInclusive: {
        required
      },
      rightValue: {
        required
      },
      rightInclusive: {
        required
      }
    },
    allNullValue: {
      required
    }
  },
})
export default class JsonQueryRule extends Vue {
  @Prop({ required: true }) public rule: JsonQueryRuleModel;
  @Prop({ required: false }) public options: JsonQueryConfigModel[];

  public popover = withPopper;
  public runPricingDataType: any = RunPricingDataType;
  public jsonQueryRuleOperator: any = JsonQueryRuleOperator;
  private originalOperatorType: JsonQueryRuleOperator | null;

  public get ruleNameOptions(): any[] {
    const options = this.options;

    return options.map((option: JsonQueryConfigModel) => {
      return {
        value: option.name,
        type: option.type,
      };
    });
  }

  public created() {
    this.originalOperatorType = this.rule.operator;
    this.populateOperators();
  }

  @Watch('rangeForm', { deep: true })
  public rangeFormChanged(): void {
    this.rule.value = this.rangeToString;
  }

  @Watch('allNullValue', { deep: true })
  public allNullValueChanged(): void {
    if (this.rule.operator === this.jsonQueryRuleOperator.AllNull ||
      JsonQueryRuleOperator[<JsonQueryRuleOperator>this.rule.operator] === this.jsonQueryRuleOperator.AllNull) {
      const transformedString = this.allNullValue?.map((x: any) => {
        return x.value;
      });
      if (transformedString && transformedString.length > 0) {
        this.rule.value = `'${transformedString?.join("','")}'`;
      } else {
        this.rule.value = null;
      }
    }
  }

  public get rangeToString(): string {
    if (this.$v.rangeForm.$invalid) {
      return '';
    }
    return `${this.rangeForm.leftValue}, ${this.rangeForm.leftInclusive}, ${this.rangeForm.rightValue}, ${this.rangeForm.rightInclusive}`;
  }

  public get trueFalseOptions(): any[] {
    return [
      { value: 'True', label: 'True' },
      { value: 'False', label: 'False' }
    ];
  }

  public get ruleOperator(): JsonQueryRuleOperator | null {
    const operator = this.ruleOperatorOptions.find((x) => x.value === this.rule.operator)
      ?.value ||
      this.ruleOperatorOptions.find((x) => x.value === JsonQueryRuleOperator[<JsonQueryRuleOperator>this.rule.operator])
        ?.value;
    if (operator) {
      this.rule.operator = operator;
    }

    return this.rule.operator;
  }

  public set ruleOperator(value: JsonQueryRuleOperator | null) {
    this.rule.operator = value;
  }

  public get ruleParams(): JsonQueryConfigModel {
    return (
      this.options.find((option: JsonQueryConfigModel) => {
        return option.name === this.rule.field;
      }) ?? { name: "", type: RunPricingDataType.STRING }
    );
  }

  public rangeForm: any = this.getEmptyRangeForm();
  public allNullValue: any[] | null = [];

  public getEmptyRangeForm(): any {
    return {
      leftValue: '',
      leftInclusive: '',
      rightValue: '',
      rightInclusive: '',
    };
  }

  public get ruleOperatorOptions(): any[] {
    if (this.ruleParams) {
      switch (this.ruleParams.type) {
        case RunPricingDataType.FLOAT:
        case RunPricingDataType.INT:
          return [
            { value: JsonQueryRuleOperator.Equals, name: "equals" },
            { value: JsonQueryRuleOperator.StringIn, name: "in" },
            { value: JsonQueryRuleOperator.Not, name: "not equals" },
            { value: JsonQueryRuleOperator.GreaterThan, name: "greater than" },
            { value: JsonQueryRuleOperator.LessThan, name: "less than" },
            { value: JsonQueryRuleOperator.WithinRange, name: "within range" },
            {
              value: JsonQueryRuleOperator.ExcludeRange,
              name: "exclude range",
            },
            { value: JsonQueryRuleOperator.IsNull, name: "is null" },
            { value: JsonQueryRuleOperator.IsNotNull, name: "is not null" },
          ];
        case RunPricingDataType.DT:
          return [
            { value: JsonQueryRuleOperator.Equals, name: "equals" },
            { value: JsonQueryRuleOperator.Not, name: "not equals" },
            { value: JsonQueryRuleOperator.GreaterThan, name: "greater than" },
            { value: JsonQueryRuleOperator.LessThan, name: "less than" },
            { value: JsonQueryRuleOperator.IsNull, name: "is null" },
            { value: JsonQueryRuleOperator.IsNotNull, name: "is not null" },
          ];
        case RunPricingDataType.BOOLEAN:
          return [
            { value: JsonQueryRuleOperator.IsTrue, name: "is true" },
            { value: JsonQueryRuleOperator.IsFalse, name: "is false" },
            // { value: JsonQueryRuleOperator.AlwaysTrue, name: "always true" },
            { value: JsonQueryRuleOperator.IsNull, name: "is null" },
            { value: JsonQueryRuleOperator.IsNotNull, name: "is not null" },
          ];
        case RunPricingDataType.LIST_FLOAT:
        case RunPricingDataType.LIST_STRING:
        case RunPricingDataType.LIST_INT:
        case RunPricingDataType.LIST_BOOLEAN:
          return [
            { value: JsonQueryRuleOperator.Equals, name: "equals" },
            { value: JsonQueryRuleOperator.Not, name: "not equals" },
            { value: JsonQueryRuleOperator.AllNull, name: "all null" },
            { value: JsonQueryRuleOperator.In, name: "in" },
            { value: JsonQueryRuleOperator.IsNull, name: "is null" },
            { value: JsonQueryRuleOperator.IsNotNull, name: "is not null" },
          ];
        default:
          return [
            { value: JsonQueryRuleOperator.Is, name: "is" },
            { value: JsonQueryRuleOperator.Not, name: "not equals" },
            { value: JsonQueryRuleOperator.StringIn, name: "in" },
            { value: JsonQueryRuleOperator.IsNull, name: "is null" },
            { value: JsonQueryRuleOperator.IsNotNull, name: "is not null" },
          ];
      }
    } else {
      return [];
    }
  }

  public get isRemoveRule(): boolean {
    const parentRules = (this.$parent as JsonQueryGroup).currentQuery.rules;

    return parentRules ? parentRules.length > 1 : false;
  }

  public get currentRuleFiled(): string {
    return this.rule.field;
  }

  public get currentRuleOperator(): JsonQueryRuleOperator | null {
    return this.rule.operator;
  }

  public isControlValid(fieldName: string): boolean {
    return checkIfValid(this.$v.rule, fieldName);
  }

  public validate(): boolean {
    this.$v.$touch();
    this.$v.rule.$touch();
    return this.$v.rule.$invalid;
  }

  public onOperatorChange(operator: JsonQueryRuleOperator): void {
    if (
      this.ruleParams.type === RunPricingDataType.BOOLEAN ||
      operator === JsonQueryRuleOperator.IsNull ||
      operator === JsonQueryRuleOperator.IsNotNull
    ) {
      this.rule.value = null;
    }
    this.populateOperators();
  }

  public onFieldChange(): void {
    const availableOperators: any[] = this.ruleOperatorOptions;
    if (
      !availableOperators.some((o) => o.value === this.rule.operator) &&
      availableOperators[0]
    ) {
      this.rule.operator = availableOperators[0].value;
    }
    this.rule.type = this.ruleParams.type;
  }

  public populateOperators(): void {
    if (this.rule.operator === this.jsonQueryRuleOperator.WithinRange ||
      this.rule.operator === this.jsonQueryRuleOperator.ExcludeRange ||
      JsonQueryRuleOperator[<JsonQueryRuleOperator>this.rule.operator] === this.jsonQueryRuleOperator.WithinRange ||
      JsonQueryRuleOperator[<JsonQueryRuleOperator>this.rule.operator] === this.jsonQueryRuleOperator.ExcludeRange
    ) {
      if (this.originalOperatorType !== this.ruleOperator &&
        <any>this.originalOperatorType !== JsonQueryRuleOperator[<JsonQueryRuleOperator>this.rule.operator]) {
        this.rangeForm = this.getEmptyRangeForm();
        return;
      }
      const operatorParameters = this.rule.value?.split(", ");
      if (operatorParameters?.length === 4) {
        this.rangeForm.leftValue = operatorParameters[0];
        this.rangeForm.leftInclusive = operatorParameters[1];
        this.rangeForm.rightValue = operatorParameters[2];
        this.rangeForm.rightInclusive = operatorParameters[3];
      }
    }
    else if (this.rule.operator === this.jsonQueryRuleOperator.AllNull ||
      JsonQueryRuleOperator[<JsonQueryRuleOperator>this.rule.operator] === this.jsonQueryRuleOperator.AllNull) {
      const transformedString = this.rule.value?.replaceAll('\'', '').split(',');
      const selectedField = this.ruleNameOptions.find(x => x.value === this.rule.field);
      if (!this.allNullValue?.includes(selectedField)) {
        this.allNullValue?.push(selectedField);
      }
      if (this.ruleNameOptions.length > 0 && transformedString) {
        this.allNullValue = this.ruleNameOptions.filter(x => transformedString?.includes(x.value));
      }
    }
    else if (this.allNullValue && this.allNullValue.length > 0) {
      this.rule.value = null;
    }
  }

  public deleteRule(): void {
    const parentRules = (this.$parent as JsonQueryGroup).currentQuery.rules;

    if (parentRules) {
      parentRules.splice(parentRules.indexOf(this.rule), 1);
    }
  }
}
