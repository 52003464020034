
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import {
  IRunPricingColumn,
  RunPricingColumnType,
  RunPricingDataType,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import { IVectorParamValue } from "@/interfaces/runPricingModel/parameters/vectorParamValue.interface";
import { getVectorRange } from "@/utils/vectorParam";
import { IVectorSegmentParam } from "@/interfaces/runPricingModel/parameters/vectorSegmentParam.interface";
import _ from "lodash";
import { operationTypes } from "./constants/operationTypes.const";
import { withPopper } from "@/plugins/popover";
import { IRunPricingRuleSettings } from "@/interfaces/runPricingModel/runPricingRuleSettings.interface";
import ParametersChangesModal from "./ParametersChangesModal.vue";
import ScenarioService from "@/services/scenario.service";
import VectorModal from "./VectorModal.vue";
import VectorParamImportModal from "./VectorParamImportModal.vue";
import VectorParamSegmentsModal from "./VectorParamSegmentsModal.vue";

@Component({
  data: () => {
    return {
      operationTypes,
    };
  },
  components: {
    "vector-param-segments-modal": VectorParamSegmentsModal,
    "vector-modal": VectorModal,
    "vector-param-import-modal": VectorParamImportModal,
  },
})
export default class VectorParam extends Vue {
  // properties
  @Prop({ required: true }) column: IRunPricingColumn;

  @Prop({
    required: false,
    default: () => {
      return { isOperationsEnable: false };
    },
  })
  settings: IRunPricingRuleSettings;

  @Prop({
    required: false,
    default: () => {
      return {
        value: {
          segments: null,
          rangeValue: null,
        },
        type: RunPricingDataType.LIST_FLOAT,
        columnName: "",
        isValid: true,
      };
    },
  })
  data: IParamData;

  public popover = withPopper;

  public chartData: any = {
    labels: [],
    datasets: [],
  };

  public segmentsModalOptions: any = {
    show: false,
    segments: null,
  };

  public vectorModalOptions: any = {
    show: false,
  };

  public importModalOptions: any = {
    show: false,
    range: null,
  };

  public created(): void { }

  public constructor() {
    super();

    if (!this.data) {
      this.data = {
        value: {
          segments: null,
          rangeValue: null,
        },
        inputValue: null,
        type: RunPricingDataType.LIST_FLOAT,
        columnName: "",
        pairName: "",
        columnType: RunPricingColumnType.Param,
        isOperation: false,
        operationType: null,
        operationValue: null,
        isValid: true,
        isOperationToggleOn: false,
      };
    }
    if (!this.data.value) {
      this.data.value = {
        segments: null,
        rangeValue: null,
      };
    }
  }

  public get isOperationSelected(): boolean {
    return (
      (this.data.operationValue &&
        this.data.operationType &&
        !this.data.isOperation) ??
      false
    );
  }

  public get isDataAvailable(): boolean {
    return (
      this.chartData.datasets.length !== 0 &&
      this.chartData.datasets.some(
        (dataset: any) => dataset.data && dataset.data.length > 0
      )
    );
  }

  public get vectorParamResult(): number[] {
    if (this.data.value?.segments && this.data.value?.segments.length) {
      return this.data.value?.segments
        .map((segment: IVectorSegmentParam) => getVectorRange(segment, 100))
        .reduce((array1: number[], array2: number[]) => array1.concat(array2));
    } else if (this.data.value?.rangeValue) {
      return this.data.value?.rangeValue;
    }

    return [];
  }

  public vectorParamCollapse: boolean = false;
  // TODO vue.js don't support chart.js version 3.0
  // public zoomIn(): void {
  //   this.lineChart.zoomIn();
  // }

  // public zoomOut(): void {
  //   this.lineChart.zoomOut();
  // }


  @Emit("receiveParamValue")
  public receiveParamValue(value: IParamData): IParamData {
    return value;
  }

  @Emit("removeParamData")
  public removeParamData(value: IParamData): IParamData {
    return value;
  }


  public openSegmentsModal(): void {
    this.segmentsModalOptions.show = true;
    this.segmentsModalOptions.segments = this.data.value?.segments ?? [];
  }

  public async openVectorModal(): Promise<void> {
    await this.updateChart(this.data.value);
    this.vectorModalOptions.show = true;
  }

  public closeVectorModal(): void {
    this.vectorModalOptions.show = false;
  }

  public closeSegmentsModal(): void {
    this.segmentsModalOptions.show = false;
  }

  public openImportModal(): void {
    this.importModalOptions.show = true;
    this.importModalOptions.range = this.data.value?.rangeValue;
  }

  public closeImportModal(): void {
    this.importModalOptions.show = false;
  }

  public deleteVector(): void {
    this.removeParamData(this.data);
  }

  public saveVector(value: IVectorParamValue): void {
    this.data.value = value;
    this.data.inputValue = value;

    this.closeSegmentsModal();
    this.closeImportModal();
    this.vectorParamCollapse = !this.vectorParamCollapse;
    this.onValueChange();
  }

  public onValueChange(): void {
    this.data.columnName = this.column.columnName;
    this.data.columnType = this.column.type;
    this.receiveParamValue(this.data);
  }

  public onOperationValueChange(): void {
    this.data.type = this.column.dataType;
    this.data.columnName = this.column.columnName;
    this.data.value =
      operationTypes.find((val) => val.value === this.data.operationType).name +
      this.data.operationValue;
    this.$emit("receiveParamValue", this.data);
  }

  public onChartDataUpdate(chartData: any): void {
    if (chartData.datasets.length === 1) {
      this.data.value = {
        segments: null,
        rangeValue: chartData.datasets[0].data,
      };
    }

    this.receiveParamValue(this.data);
  }

  public onUpdateOperations(value: boolean): void {
    if (value) return;
    if (this.data.operationType && this.data.operationValue) {
      this.data.value =
        operationTypes.find((val) => val.value === this.data.operationType)
          .name + this.data.operationValue;
    }
  }

  private async updateChart(value: IVectorParamValue): Promise<void> {
    this.chartData = {
      labels: [],
      datasets: [],
    };

    if (value?.segments) {
      for (let [index, segment] of value?.segments.entries()) {
        const segmentValues: number[] = await ScenarioService.generateSegment(segment);
        const dataset: any = this.getDefaultDataset(false);

        dataset.data.push(...Array.from({ length: this.chartData.labels.length }, () => NaN));
        dataset.data.push(...segmentValues);

        if (value?.segments && index === value?.segments.length - 1 && segment.start === value?.segments[index - 1]?.end) {
          segmentValues.shift();
          this.chartData.datasets[this.chartData.datasets.length - 1].data.push(...segmentValues);
        } else {
          this.chartData.datasets.push(dataset);

          if (value?.segments && index !== value?.segments.length - 1 && segment.end !== value?.segments[index + 1].start) {
            const breakSegmentDataset = this.getDefaultDataset(true);
            breakSegmentDataset.data.push(...Array.from({ length: dataset.data.length - 1 }, () => NaN));

            breakSegmentDataset.data.push(segment.end);
            breakSegmentDataset.data.push(value?.segments[index + 1].start);
            this.chartData.datasets.push(breakSegmentDataset);
          }
        }

        const datasetLabels: number[] = Array.from(
          { length: segmentValues.length },
          (_item: number, i: number) => i + this.chartData.labels.length + 1
        );
        this.chartData.labels.push(...datasetLabels);
      }
    } else if (value?.rangeValue) {
      this.chartData.labels = Array.from(
        { length: value?.rangeValue.length },
        (_item: number, index: number) => index + 1
      );

      const dataset: any = this.getDefaultDataset(false);
      dataset.data = value?.rangeValue;
      this.chartData.datasets.push(dataset);
    }
  }

  private getDefaultDataset(isSegmentBreak: boolean): any {
    return {
      fill: false,
      borderColor: isSegmentBreak ? "rgba(238, 118, 0, 0.32)" : "#EE7600",
      data: [],
      pointStyle: "circle",
      pointRadius: 4,
      pointBackgroundColor: "#EE7600",
      dragData: true,
    };
  }
}
