import { IImportRule } from '@/interfaces/importRules/import-rule.interface';
import { FiltrationState } from '@/models/filtrationState';
import { PaginatedResult } from '@/models/paginatedResult';
import { ApiBaseService } from './api-base-service';

class ImportTemplateRuleService extends ApiBaseService {
    private baseEndpoint = "/importTemplateRules";

    public getTemplateRules = (searchState: FiltrationState): Promise<PaginatedResult<IImportRule>> =>
        this.getFromSearchParams<IImportRule>(this.baseEndpoint, searchState);

    public getTemplateRule = (id: number): Promise<IImportRule> => this.getById<IImportRule>(this.baseEndpoint, id);

    public createTemplateRule = (templateRule: IImportRule): Promise<any> => this.post<any>(this.baseEndpoint, templateRule);

    public updateTemplateRule = (id: number, templateRule: IImportRule): Promise<number> => this.put<number>(this.baseEndpoint, id, templateRule);

    public removeTemplateRule = (id: number): Promise<object> => this.delete<object>(this.baseEndpoint, id);
}

export default new ImportTemplateRuleService();
