
import { Component, Vue, Emit, Ref, Prop, Watch } from 'vue-property-decorator';
import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FileStatus } from 'filepond';

// Create component
const filePond = vueFilePond(FilePondPluginFileValidateType);

@Component({
  components: {
    'file-pond': filePond
  }
})
export default class FileUploader extends Vue {
  public labelIdle: string = `
  <div class="uploader-title">
    <img src="/icons/drag&drop.svg"/>
    <span>Drag and drop a file.<span class="filepond--label-action">Click to add.</span></span>
  </div>`;

  public fileStatus: number = 0;

  // refs
  @Ref() public readonly uploader!: VueFilePondComponent;

  // emits
  @Prop({ required: true }) public value: File | null;

  @Watch('value')
  onFileChanged(file: File | null): void {
    const existfileMetadata = this.uploader.getFile();
    if (
      !existfileMetadata ||
      (existfileMetadata && existfileMetadata.status !== FileStatus.LOAD_ERROR)
    ) {
      if (file) {
        if (file !== existfileMetadata?.file) {
          this.uploader.removeFiles();
          this.uploader.addFile(file);
        }
      } else {
        this.uploader.removeFiles();
      }
    }
  }

  @Emit('input')
  onUploaderChange(file: File | null): File | null {
    const existfileMetadata = this.uploader.getFile();
    this.fileStatus = existfileMetadata ? existfileMetadata.status : 0;

    return file;
  }

  uploadFile(error: any, fileMetadata: any): void {
    if (error) {
      this.onUploaderChange(null);
    } else {
      this.onUploaderChange(fileMetadata.file);
    }
  }
}
