var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"dialog"}},[_c('c-modal',{staticClass:"no-footer modal-full-size",attrs:{"show":true,"closeOnBackdrop":false,"centered":true,"size":"xl"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"modal-title"},[_vm._v("Subset rule")]),_c('c-button-close',{on:{"click":_vm.onClose}})]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center mt-2 my-3"},[_c('label',{staticClass:"mr-4 mb-0",class:{
          'invalid-data':
            _vm.$v.form.name.$dirty &&
            _vm.$v.form.name.$invalid &&
            !_vm.$v.form.name.$pending,
        }},[_vm._v("Name *")]),_c('c-input',{staticClass:"mb-0",attrs:{"isValid":!(_vm.$v.form.name.$dirty && _vm.$v.form.name.$invalid && !_vm.$v.form.name.$pending),"lazy":false,"value":_vm.$v.form.name.$model,"placeholder":"Enter the name","addLabelClasses":{
            'invalid-data':
              _vm.$v.form.name.$dirty &&
              _vm.$v.form.name.$invalid &&
              !_vm.$v.form.name.$pending,
          }},on:{"change":_vm.updateName}})],1),(_vm.$v.form)?_c('c-form',{staticClass:"form-container mt-2"},[_c('c-row',{staticClass:"d-flex pb-4 flex-wrap"},[_c('c-col',{staticClass:"col-lg",attrs:{"md":"12","sm":"12"}},[(_vm.form.rule)?_c('div',[_c('json-query-builder',{ref:"queryBuilder",attrs:{"options":_vm.queryOptions},on:{"input":function($event){return _vm.isControlValid('rule')}},model:{value:(_vm.form.rule),callback:function ($$v) {_vm.$set(_vm.form, "rule", $$v)},expression:"form.rule"}})],1):_vm._e()])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('div',[_c('c-button',{staticClass:"btn-filled large d-flex align-items-center",on:{"click":_vm.submit}},[_c('img',{staticClass:"mr-2",attrs:{"src":"/icons/save.svg"}}),_vm._v("Save The Rule ")]),(_vm.$v.form.name.$dirty && !_vm.$v.form.name.$pending)?_c('div',[(!_vm.$v.form.name.required)?_c('div',{staticClass:"validation-error-message text-center"},[_vm._v(" Name is required. ")]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('div',{staticClass:"validation-error-message text-center"},[_vm._v(" Name must have at most "+_vm._s(_vm.$v.form.name.$params.maxLength.max)+" letters. ")]):_vm._e(),(!_vm.$v.form.name.unique)?_c('div',{staticClass:"validation-error-message text-center"},[_vm._v(" Name already exists. ")]):_vm._e()]):_vm._e()],1)])]},proxy:true},{key:"footer",fn:function(){return undefined},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }