
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import VectorLineChart from './VectorLineChart.vue';

@Component({
  components: {}
})
export default class VectorModal extends Vue {
  @Prop({ required: true, default: {} })
  public chartData: any;

  @Prop({ required: true, default: {} })
  public show: boolean;

  @Prop({ required: true, default: false })
  public isDataAvailable: boolean;

  @Ref() public readonly lineChart: VectorLineChart;

  @Emit('close')
  public onClose(): void {
    return;
  }

  @Emit('chart-data:update')
  public onChartDataUpdate(data: any): any {
    return data;
  }

  public resetZoom(): void {
    this.lineChart.resetZoom();
  }
}
