import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CIcon,
  CInput,
  CInputCheckbox,
  CInputRadioGroup,
  CLink,
  CRow,
  CSelect,
  CBadge,
  CModal,
  CButtonClose,
  CNavbar,
  CToaster,
  CToggler,
  CCollapse,
  CNavbarNav,
  CNavItem,
  CDropdown,
  CDropdownItem,
  CNavbarBrand,
  CListGroup,
  CListGroupItem,
  CInputRadio,
  CClickaway,
  CTabs,
  CTab,
  CTextarea,
  CTooltip,
  CSwitch,
  CDataTable
} from '@coreui/vue';
import { freeSet as icons } from '@coreui/icons';
import { VueSelect } from 'vue-select';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import zoomPlugin from 'chartjs-plugin-zoom';
import FileUploader from './components/ui/FileUploader.vue';
import BaseModal from './components/ui/BaseModal.vue';
import Vuelidate from 'vuelidate';
import { Component } from 'vue-property-decorator';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueToast from 'vue-toast-notification';
import CustomDatePicker from './components/ui/CustomDatePicker.vue';
import Loader from './components/ui/Loader.vue';
import AutoComplete from './components/ui/AutoComplete.vue';
import ConfirmOperationModal from './components/ui/ConfirmOperationModal.vue';
import ConfirmOperationDirective from './directives/confirmOperation.directive';
import { NotificationHub } from './hubs/notification.hub';
import Adal from 'vue-adal';
import DisabledDirective from './directives/disabled.directive';
import StandardizeAddressModal from './components/standardize-addresses/StandardizeAddressModal.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import SubsetRuleModal from '@/components/run-pricing-model/SubsetRuleModal.vue';
import JsonQueryBuilder from '@/components/json-query-builder/JsonQueryBuilder.vue';
import JsonQueryGroup from '@/components/json-query-builder/JsonQueryGroup.vue';
import JsonQueryRule from '@/components/json-query-builder/JsonQueryRule.vue';
import VectorLineChart from '@/components/run-pricing-model/parameters/VectorLineChart.vue';
import ParametersChangesModal from '@/components/run-pricing-model/parameters/ParametersChangesModal.vue';
import BreadCrumbs from '@/components/layouts/BreadCrumb.vue';
import Chart from 'chart.js';
import draggable from 'vuedraggable';
import { truncateFilter } from './filters/truncate.filter';
import dragdataPlugin from 'chartjs-plugin-dragdata';
import Multiselect from 'vue-multiselect';

Vue.config.productionTip = false;

// register auth services
Vue.use(Adal, {
  config: {
    tenant: process.env?.VUE_APP_ADAL_CONFIG_TENANT,
    clientId: process.env?.VUE_APP_ADAL_CONFIG_CLIENT_ID,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage'
  },
  requireAuthOnInitialize: true,
  router
})

// register packages
Vue.use(Vuelidate);
Vue.use(VueToast, {
  duration: 3000,
  position: 'bottom-left'
});
Vue.use(VueLodash, { name: 'custom', lodash });
Chart.plugins.register(zoomPlugin);
Chart.plugins.register(dragdataPlugin);

// common CoreUI components
Vue.component('c-navbar', CNavbar);
Vue.component('c-button', CButton);
Vue.component('c-icon', CIcon)
Vue.component('file-uploader', FileUploader);
Vue.component('base-modal', BaseModal);
Vue.component('c-card', CCard);
Vue.component('c-card-header', CCardHeader);
Vue.component('c-card-footer', CCardFooter);
Vue.component('c-card-body', CCardBody);
Vue.component('c-link', CLink);
Vue.component('c-row', CRow);
Vue.component('c-col', CCol);
Vue.component('c-form', CForm);
Vue.component('c-input', CInput);
Vue.component('c-textarea', CTextarea);
Vue.component('c-input-checkbox', CInputCheckbox);
Vue.component('c-select', CSelect);
Vue.component('c-input-radio-group', CInputRadioGroup);
Vue.component('c-input-radio', CInputRadio);
Vue.component('c-badge', CBadge);
Vue.component('v-select', VueSelect);
Vue.component('c-modal', CModal);
Vue.component('c-button-close', CButtonClose);
Vue.component('c-toaster', CToaster);
Vue.component('c-toggler', CToggler);
Vue.component('c-collapse', CCollapse);
Vue.component('c-navbar-nav', CNavbarNav);
Vue.component('c-nav-item', CNavItem);
Vue.component('c-dropdown', CDropdown);
Vue.component('c-dropdown-item', CDropdownItem);
Vue.component('c-navbar-brand', CNavbarBrand);
Vue.component('c-date-picker', CustomDatePicker);
Vue.component('c-list-group', CListGroup);
Vue.component('c-list-group-item', CListGroupItem)
Vue.component('c-switch', CSwitch);
Vue.component('loader', Loader);
Vue.component('autocomplete', AutoComplete);
Vue.component('c-tabs', CTabs);
Vue.component('c-tab', CTab);
Vue.component('c-data-table', CDataTable);
Vue.component('confirm-operation-modal', ConfirmOperationModal);
Vue.component('standardize-address-modal', StandardizeAddressModal);
Vue.component('vue-tags-input', VueTagsInput);
Vue.component('vector-line-chart', VectorLineChart);
Vue.component('parameters-changes-modal', ParametersChangesModal);

// register custom hooks
Component.registerHooks(['validations']);

// register directives
Vue.directive('click-outside', CClickaway);
Vue.directive('confirm-operation', ConfirmOperationDirective);
Vue.directive('disabled', DisabledDirective);
Vue.directive('c-tooltip', CTooltip);
Vue.component('multiselect', Multiselect)

// register filters
Vue.filter('truncate', truncateFilter);

// register query builder components
Vue.component('subset-rule-modal', SubsetRuleModal);
Vue.component('json-query-builder', JsonQueryBuilder);
Vue.component('json-query-group', JsonQueryGroup);
Vue.component('json-query-rule', JsonQueryRule);
Vue.component('draggable', draggable);
Vue.component('BreadCrumbs', BreadCrumbs);

new Vue({
  router,
  store,
  icons,
  render: (h) => h(App),
  mounted: () => {
    NotificationHub.startConnection();
  }
}).$mount('#app');