import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { RunPricingModelState } from '@/models/runPricingModelState';
import httpClient from '@/plugins/httpClient';
import { IRunPricingModelConfig, RunPricingColumnType, RunPricingDataType } from '@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface';
import { IGlobalRule } from '@/interfaces/runPricingModel/globalRule.interface';
import { ILocalRule } from '@/interfaces/runPricingModel/localRule.interface';
import { ILocalRuleDetails } from '@/interfaces/runPricingModel/localRuleDetails.interface';
import { IParameterAdjustmentDetails } from '@/interfaces/runPricingModel/parameterAdjustmentDetails.interface';
import { RPMPagesType } from '@/enums/rpm-pages-type';
import { NameValuePair } from '@/models/NameValuePair';
import { IParamData } from '@/interfaces/runPricingModel/parameters/commonParam.interface';
import { IYtmSpreadData } from "@/interfaces/runPricingModel/subsetRuleDetails.interface";
import { IScenarioDetails } from '@/interfaces/runPricingModel/scenario/scenarioDetails.interface';

@Module({
    name: 'runPricingModelModule',
    namespaced: true,
    dynamic: true,
    store
})
export default class RunPricingModelModule extends VuexModule {
    public runPricingModelState: RunPricingModelState | null = null;
    public runPricingParametersInfoState: IRunPricingModelConfig | null = null;

    @Mutation
    public updateState(state: RunPricingModelState): void {
        this.runPricingModelState = state;
    }

    @Mutation
    public setRunPricingParameterInfo(state: IRunPricingModelConfig): void {
        this.runPricingParametersInfoState = state;
    }

    @Action
    public async getRunPricingParametersInfo(): Promise<IRunPricingModelConfig> {
        const response: IRunPricingModelConfig = await httpClient.get<IRunPricingModelConfig>(`/runPricing/info/parameters`);
        this.setRunPricingParameterInfo(response);

        return response;
    }

    @Action
    public async checkFormIsDirty(payload: { form: any, pristineForm: any, type: RPMPagesType; }): Promise<boolean> {
       if(!payload.form || !payload.pristineForm)
            return false;

        const form = JSON.parse(JSON.stringify(payload.form));
        const pristineForm = JSON.parse(JSON.stringify(payload.pristineForm));
        switch(payload.type) {
            case RPMPagesType.GlobalRule: {
                return this.checkGlobalRuleDirty({form: form, pristineForm: pristineForm});
            }
            case RPMPagesType.LocalRule: {
                return this.checkLocalRuleDirty({form: form, pristineForm: pristineForm});
            }
            case RPMPagesType.ParameterAdjustment: {
                return this.checkParameterAdjustmentDirty({form: form, pristineForm: pristineForm});
            }
            case RPMPagesType.Scenario: {
                return this.checkScenarioDirty({form: form, pristineForm: pristineForm});
            }
        }
    }

    @Action
    public async checkGlobalRuleDirty(payload: {form: IGlobalRule, pristineForm: IGlobalRule}): Promise<boolean> {
       let isDirty: boolean = false;
       isDirty = 
            !(payload.form.name == payload.pristineForm.name &&
            payload.form.id == payload.pristineForm.id &&
            payload.form.description == payload.pristineForm.description &&
            payload.form.settings.isOperationsEnable == payload.pristineForm.settings.isOperationsEnable &&
            payload.form.sequence == payload.pristineForm.sequence &&
            payload.form.isYtm == payload.pristineForm.isYtm);

        isDirty = payload.form.parameters.length > 0 ? 
            await this.checkParametersDirty({ params: payload.form.parameters, originalParams: payload.pristineForm.parameters}) || isDirty : isDirty;

        return isDirty;
    }

    @Action
    public checkLocalRuleDirty(payload: {form: ILocalRuleDetails, pristineForm: ILocalRuleDetails}): boolean {
        let isDirty: boolean = false;
        isDirty = 
            !(payload.form.name == payload.pristineForm.name &&
            payload.form.id == payload.pristineForm.id &&
            payload.form.description == payload.pristineForm.description &&
            payload.form.isAlwaysTrueSubsetRules == payload.pristineForm.isAlwaysTrueSubsetRules &&
            payload.form.sequence == payload.pristineForm.sequence &&
            payload.form.subsetRuleId == payload.pristineForm.subsetRuleId &&
            payload.form.parameterAdjustmentId == payload.pristineForm.parameterAdjustmentId);

        return isDirty;
    }

    @Action
    public async checkParameterAdjustmentDirty(payload: {form: IParameterAdjustmentDetails, pristineForm: IParameterAdjustmentDetails}): Promise<boolean> {
        let isDirty: boolean = false;
        
        isDirty = 
            !(payload.form.name == payload.pristineForm.name &&
            payload.form.id == payload.pristineForm.id &&
            payload.form.settings.isOperationsEnable == payload.pristineForm.settings.isOperationsEnable);
    
        isDirty = payload.form.parameters.length > 0 ? 
            await this.checkParametersDirty({params: payload.form.parameters, originalParams: payload.pristineForm.parameters}) || isDirty : isDirty;

        isDirty = payload.form.ytmSpreadData && payload.pristineForm.ytmSpreadData ? 
                await this.checkYTMSpreadDirty({ ytmSpreadData: payload.form.ytmSpreadData, originalYtmSpreadData: payload.pristineForm.ytmSpreadData})
                || isDirty : isDirty;

        return isDirty;
    }

    @Action
    public async checkScenarioDirty(payload: {form: IScenarioDetails, pristineForm: IScenarioDetails}): Promise<boolean> {
        let isDirty: boolean = false;

        isDirty = 
            !(payload.form.name == payload.pristineForm.name &&
            payload.form.id == payload.pristineForm.id);

        if(payload.form.localRules.length !== payload.pristineForm.localRules.length || 
            payload.form.globalRules?.length !== payload.pristineForm.globalRules?.length)
                return true;
        
        isDirty = payload.form.localRules.length > 0 ? 
            await this.checkRulesDirty({rules: payload.form.localRules, originalRules: payload.pristineForm.localRules}) || isDirty : isDirty;

        isDirty = payload.form.globalRules.length > 0 ? 
            await this.checkRulesDirty({rules: payload.form.globalRules, originalRules: payload.pristineForm.globalRules}) || isDirty : isDirty;

        return isDirty;
    }

    @Action
    private checkParametersDirty(payload: {params: IParamData[], originalParams: IParamData[]}): boolean {
        let isDirty: boolean = false;

        if(payload.params?.length !== payload.originalParams?.length)
            return true;

        if(payload.params?.length > 0) {
            isDirty = !payload.params.every((value) => {
                const parameter = payload.originalParams.find(x => x.columnName === value.columnName && x.type === value.type);
                switch(parameter?.type) {
                    case RunPricingDataType.LIST_FLOAT: {
                        if(parameter.inputValue?.rangeValue != value.inputValue?.rangeValue ||
                            parameter.inputValue?.segments?.length !== value.inputValue?.segments?.length)
                            return false;
                        
                        if(parameter.inputValue?.segments?.length > 0) {
                            for (const [i, obj] of parameter.inputValue?.segments?.entries()) {
                                if(obj.start != value.inputValue?.segments[i]?.start || 
                                    obj.end != value.inputValue?.segments[i]?.end ||
                                    obj.vectorLength != value.inputValue?.segments[i]?.vectorLength)
                                    return false;
                            }
                        }
                        return true;
                    }
                    case RunPricingDataType.TABLE: {
                        const tableParams = payload.params.filter(x => x.type === RunPricingDataType.TABLE &&
                            x.inputValue && (<Array<NameValuePair>>x.inputValue).every((v: { value: any; }) => !v.value));
                        const originalTableParams = payload.originalParams.filter(x => x.type === RunPricingDataType.TABLE &&
                            x.inputValue && (<Array<NameValuePair>>x.inputValue).every((v: { value: any; }) => !v.value));
                
                        payload.params = payload.params.filter(p => !tableParams.includes(p));
                        payload.originalParams = payload.originalParams.filter(p => !originalTableParams.includes(p));
    
                        if(parameter.inputValue[0].value != value.inputValue[0].value ||
                            parameter.inputValue[1].value != value.inputValue[1].value) 
                            return false;
                        
                        return true;
                    }
                    case RunPricingDataType.STRING: {
                        if(parameter.columnType === RunPricingColumnType.PairedConstructorParam) {
                            for(const [i, obj] of value.inputValue?.entries()) {
                                const originalParametersMap = parameter.inputValue.find((x: { name: any; }) => x.name == obj.name)?.parametersMaps[0];
                                const originalPair = parameter.inputValue.find(((x: { name: any; pair: { name: any; }; }) => 
                                    x.name == obj.name && x.pair.name == obj.pair.name))?.pair?.parametersMaps[0];

                                for(const key in obj.parametersMaps[0]) {
                                    if(obj.parametersMaps[0][key]?.inputValue != originalParametersMap[key].inputValue) {
                                        return false;
                                    }
                                }

                                for(const key in obj.pair?.parametersMaps[0]) {
                                    if(obj.pair?.parametersMaps[0][key]?.inputValue != originalPair[key].inputValue) {
                                        return false;
                                    }
                                }
                            }

                            return true;
                        }
                    }
                }
    
                if(!parameter || value.inputValue != parameter.inputValue)
                    return false;
    
                return true;
            });
        }
    
        return isDirty;
    }
    
    @Action
    private checkYTMSpreadDirty(payload: {ytmSpreadData: IYtmSpreadData[], originalYtmSpreadData: IYtmSpreadData[]}): boolean {
        let isDirty: boolean = false;

        if(payload.ytmSpreadData?.length !== payload.originalYtmSpreadData?.length)
            return true;

        if(payload.ytmSpreadData?.length > 0) {
            isDirty = payload.ytmSpreadData?.every((value) => {
                const parameter = payload.originalYtmSpreadData?.find(x => x.columnName === value.columnName && x.rowName === value.rowName);
                if(!parameter || value.value != parameter.value)
                    return true;
    
                return false;
            });
        }

        return isDirty;
    }
    
    @Action 
    private checkRulesDirty(payload: {rules: any[], originalRules: any[]}): boolean {
        let isDirty: boolean = false;

        isDirty = payload.rules.every((value) => {
            const parameter = payload.originalRules.find(x => x.id === value.id);
            if(!parameter)
                return true;

            return false;
        });

        return isDirty;
    }
}
