
import { Component, Ref, Vue } from 'vue-property-decorator';
import DealSummary from '@/components/deals/DealSummary.vue';
import MappingEditor from '@/components/import-mapping/MappingEditor.vue';
import { IDeal } from '@/interfaces/deals/deal.interface';
import { IFileInfoMapping } from '@/interfaces/importMapping/file-info-mapping.interface';
import TapeCrackerModule from '@/store/modules/tape-cracker.module';
import { getModule } from 'vuex-module-decorators';
import { IImportMapping } from '@/interfaces/tapeCrackers/import-mapping.interface';
import ImportTemplateFormModal from '@/components/import-templates/ImportTemplateFormModal.vue';
import { ImportTemplateOperationType } from '@/enums/import-template-operation-type';
import { ApiResponseType } from '@/enums/api-response-type';
import { ModalState } from '@/models/modalState';
import { IImportTemplateUpdateModal } from '@/interfaces/importTemplates/import-templat-update-modal.interface';
import { IImportRawDataCommand } from '@/interfaces/tapeCrackers/import-raw-data-command.interface';

@Component({
  components: {
    'deal-summary': DealSummary,
    'mapping-editor': MappingEditor,
    'import-template-form-modal': ImportTemplateFormModal
  }
})
export default class TapeCracker extends Vue {
  // refs
  @Ref() public readonly dealSummary!: DealSummary;

  public importTemplateId: number | null = null;
  public importTemplate: any | null = {
    id: null,
    name: null,
    mappings: null
  };

  public isLoadMapColumns: boolean = false;

  public isShowImportTemplateModal: boolean = false;

  public uploadFileCardCollapse: boolean = true;
  public mapImportCardCollapse: boolean = true;

  public confirmationModalState: ModalState = {
    show: false,
    title: 'Create template',
    message: 'Do you want to create a new template?',
    size: 'sm'
  };

  public updateCurrentTemplateOrCreateModalState: any = {
    show: false,
    size: 'sm',
    isUpdate: 'true'
  };

  public importFailedModalState: ModalState = {
    show: false
  };

  private dealInfo: IDeal | null = null;
  private uploadedFile: File | null = null;
  private importMappings: IImportMapping[] | null = null;

  private tapeCrackerModule: TapeCrackerModule;
  private mappingOptionsOriginal: IImportMapping[] = [];

  public constructor() {
    super();

    this.tapeCrackerModule = getModule(TapeCrackerModule);
  }

  public uploadFile(file: File | null): void {
    this.resetMappings();
    this.uploadedFile = file;
  }

  public setImportTemplateId(template: any | null): void {
    this.importTemplateId = template?.value;
    this.importTemplate.id = template?.value;
  }

  public mapColumns(deal: IDeal): void {
    this.isLoadMapColumns = false;
    if (this.uploadedFile) {
      this.dealInfo = deal;
      this.tapeCrackerModule
        .getFileInfoAndMapping({
          importFile: this.uploadedFile,
          importTemplateId: this.importTemplateId
        })
        .then((response: IFileInfoMapping) => {
          this.isLoadMapColumns = true;
          this.uploadFileCardCollapse = false;

          if (this.importTemplateId) {
            this.mappingOptionsOriginal = response.mappings
              .filter((e) => e.dbColumn && e.dbColumn !== 'NaN')
              .map((m) => {
                return {
                  fileColumn: m.fileColumn,
                  dbColumn: m.dbColumn,
                  sampleValues:
                    m.dataSamples && m.dataSamples.length > 0
                      ? [m.dataSamples[0]]
                      : [],
                  mappedBy: m.matchType
                };
              });
          }
        });
    } else {
      Vue.$toast.error('Uploaded file not found!');
    }
  }

  public getImportMappingOptions(importMappings: IImportMapping[]): void {
    if (this.dealInfo && this.uploadedFile) {
      this.importMappings = importMappings;
      if (this.importTemplateId && this.isMappingChange(importMappings)) {
        this.importDataWithoutTemplate();
      } else {
        this.confirmationModalState.title = `${this.importTemplateId ? 'Update' : 'Create'
          } template`;
        this.confirmationModalState.message = this.importTemplateId
          ? 'Do you want to save changes to the template?'
          : 'Do you want to create a new template?';
        this.confirmationModalState.size = this.importTemplateId ? 'lg' : 'sm';
        this.confirmationModalState.show = true;
        this.isShowImportTemplateModal = false;
      }
    } else {
      Vue.$toast.error('Uploaded file or deal information not found!');
    }
  }

  public openImportTemplateModal(): void {
    this.isShowImportTemplateModal = true;
  }

  public createOrUpdateTemplate(template: IImportTemplateUpdateModal): void {
    this.importTemplate.name = template.newTemplateChosen
      ? template.newTemplateName
      : template.existingTemplateName;

    const importCommand = this.getImportRawDataCommand();
    if (importCommand) {
      importCommand.importTemplate = this.importTemplate;
      importCommand.templateOperationType =
        !template.newTemplateChosen && this.importTemplateId
          ? ImportTemplateOperationType.Update
          : ImportTemplateOperationType.Create;

      this.importRawData(importCommand);
    }
  }

  public importDataWithoutTemplate(): void {
    const importCommand = this.getImportRawDataCommand();

    if (importCommand) {
      this.importRawData(importCommand);
    }
  }

  public closeImportModal(): void {
    this.confirmationModalState.show = false;
    this.isShowImportTemplateModal = false;
  }

  private isMappingChange(mappings: IImportMapping[]): boolean {
    for (let i = 0; i < mappings.length; i++) {
      if (
        !(
          mappings[i].fileColumn ===
          this.mappingOptionsOriginal[i].fileColumn &&
          mappings[i].dbColumn === this.mappingOptionsOriginal[i].dbColumn
        )
      ) {
        return false;
      }
    }

    return true;
  }

  private importRawData(importCommand: IImportRawDataCommand): void {
    this.tapeCrackerModule
      .import(importCommand)
      .then((resp) => {
        if (resp.type === ApiResponseType.Success) {
          Vue.$toast.success('File was imported successfully.');
          this.reset();
        } else {
          this.confirmationModalState.show = false;
          this.isShowImportTemplateModal = false;
          this.importFailedModalState.show = true;
          this.importFailedModalState.message = resp.message;
        }
      })
      .finally(() => {
        this.dealSummary.loadImportTemplates();
        this.dealSummary.loadLoanRawMetadata();
      });
  }

  private reset(): void {
    this.resetMappings();
    this.resetDealInfo();
  }

  private resetMappings(): void {
    this.importTemplateId = null;
    this.importFailedModalState.show = false;
    this.confirmationModalState.show = false;
    this.isShowImportTemplateModal = false;
    this.uploadFileCardCollapse = true;
    this.importMappings = null;
    this.isLoadMapColumns = false;
    this.tapeCrackerModule.setFileInfoAndMappings({
      fileInfo: {
        columnsInfo: [],
        recordsCount: 0
      },
      mappings: []
    });
  }

  private resetDealInfo(): void {
    this.dealInfo = null;
    this.uploadedFile = null;
    this.dealSummary.reset();
  }

  private getImportRawDataCommand(): IImportRawDataCommand | null {
    if (this.dealInfo && this.uploadedFile && this.importMappings) {
      return {
        deal: {
          name: this.dealInfo.name,
          revisionName: this.dealInfo.revisionName,
          sellerName: this.dealInfo.sellerName,
          saleManagedBy: this.dealInfo.saleManagedBy,
          collateralType: this.dealInfo.collateralType,
          competitiveNegotiated: this.dealInfo.competitiveNegotiated,
          key: this.dealInfo.key,
          bidDate: this.dealInfo.bidDate,
          settleDate: this.dealInfo.settleDate,
          cutoffDate: this.dealInfo.cutoffDate,
          isDepositRequired: this.dealInfo.isDepositRequired,
          depositAmountRequired: this.dealInfo.depositAmountRequired,
          importTemplateId: this.importTemplateId,
          importFile: this.uploadedFile,
          mappings: this.importMappings
        },
        templateOperationType: ImportTemplateOperationType.Skip
      };
    } else {
      Vue.$toast.error('Data to import is invalid!');
      return null;
    }
  }
}
