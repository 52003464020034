
import { Component, Vue } from "vue-property-decorator";
import { withPopper } from "@/plugins/popover";
import ScenarioService from "@/services/scenario.service";
import { ParametersService } from "@/services/parameters.service";
import { operationTypes } from "@/components/run-pricing-model/parameters/constants/operationTypes.const";
import RulesList from "../../components/run-pricing-model/RulesList.vue";
import { PaginatedResult } from "@/models/paginatedResult";
import { IGlobalRule } from "@/interfaces/runPricingModel/globalRule.interface";
import { FiltrationState } from "@/models/filtrationState";
import { maxItemsInDropDown } from "@/constants/stateConstants";
import { IRule } from "@/interfaces/runPricingModel/rule.interface";
import { IScenarioTable } from "@/interfaces/runPricingModel/scenario/scenarioTable.interface";
import { IScenario } from "@/interfaces/runPricingModel/scenario/scenario.interface";

@Component({
  components: {
    "rules-list": RulesList,
  },
  methods: {},
  data() {
    return {
      isRouteLeaving: false,
      isRedirectToPrevious: false,
      nextRouteName: String,
      operationTypes,
      nextRoute: Function,
      parametersService: ParametersService,
    };
  },
})
export default class GlobalRules extends Vue {
  // refs

  public popover = withPopper;
  public ruleModalText: string = "";
  public ruleModalShow: boolean = false;
  private numberOfRulesLoaded: number;
  public numberOfItemsLoaded: number = 0;
  public isLoadMore: boolean;
  public search: string | null = null;
  public scenarios: IScenarioTable[] = [];

  public fields: any = [
    { key: "expandable", _style: "width:50px", label: "" },
    { key: "name" },
    { key: "buttons", label: "Actions", _style: "width:120px" },
  ];

  public get scenariosOptions(): IScenarioTable[] {
    return [
      ...this.scenarios.map((scenario: IScenarioTable, index: number) => {
        return {
          id: scenario.id,
          name: scenario.name,
          _classes: index % 2 > 0 ? "odd-row" : "even-row",
          _toggled: scenario._toggled,
          globalRules: scenario.globalRules,
          localRules: scenario.localRules,
        };
      }),
    ];
  }

  public constructor() {
    super();
    const state: FiltrationState = this.createSearchState(null, 0);
    this.loadScenarios(state, false);
  }

  public created(): void { }

  public searchScenarios(value: string): void {
    const state: FiltrationState = this.createSearchState(value, 0);
    this.loadScenarios(state, false);
  }

  private loadScenarios(state: FiltrationState, isLoadMore: boolean): void {
    ScenarioService.getScenarios(state).then(
      (resp: PaginatedResult<IScenario>) => {
        if (isLoadMore) {
          for (const scenario of resp.entities) {
            this.scenarios.push(scenario as IScenarioTable);
          }
        } else {
          this.scenarios = resp.entities as IScenarioTable[];
        }

        // this.scenarios.forEach((scenario, index) => {
        //   scenario._classes = index % 2 > 0 ? "odd-row" : "even-row";
        //   scenario._toggled = false;
        // });
        this.numberOfItemsLoaded += resp.entities.length;
        if (resp.totalCount > this.numberOfItemsLoaded) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
          this.numberOfItemsLoaded = 0;
        }
      }
    );
  }

  public createSearchState(
    search: string | null,
    skipCount: number
  ): FiltrationState {
    const state: FiltrationState = {
      showDeleted: false,
      skip: skipCount,
      take: maxItemsInDropDown,
    };

    if (search !== this.search) {
      this.numberOfRulesLoaded = 0;
    }

    this.search = search;

    if (search) {
      state.filter = {
        logic: "or",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: search,
            ignoreCase: true,
          },
        ],
      };
    }
    return state;
  }

  public editRule(ruleId: number): void {
    this.$router.push({
      name: "GlobalRule",
      params: { id: ruleId.toString() },
    });
  }

  public toggleDetails(item: IScenarioTable) {
    if (!item.id) {
      return;
    }
    let scenario = this.scenarios.find((val) => val.id === item.id);
    const scenarioIndex = this.scenarios.findIndex((val) => val.id === item.id);
    if (!scenario) {
      return;
    }
    if (scenario._toggled) {
      scenario._toggled = false;
      this.$set(this.scenarios, scenarioIndex, scenario);
      return;
    }
    ScenarioService.getScenario(item.id).then((value) => {
      if (scenario) {
        scenario.globalRules = value.globalRules;
        scenario.localRules = value.localRules;
        scenario._toggled = true;
        this.$set(this.scenarios, scenarioIndex, scenario);
      }
    });
  }

  public editScenario(id: number): void {
    this.$router.push({
      name: "Scenario",
      params: { id: id.toString() },
    });
  }
}
