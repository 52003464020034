
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Route, RouteConfig } from "vue-router";

@Component({})
export default class BreadCrumbs extends Vue {
  public stack: any[] = [];

  @Prop({
    required: false,
    default: "Scenario",
  })
  parentPath: string;

  public get stackOptions(): any[] {
    return [...this.stack];
  }

  public created(): void {
    this.buildStack();
  }

  public buildStack(): any[] {
    this.stack = [];
    this.addPage(this.$router.currentRoute);
    this.stack[0].label = this.stack[0].label.replace("/", "");
    this.stack = this.stack.reverse();
    return this.stack;
  }

  public addPage(route: Route): void {
    this.stack.push({
      name: route?.name,
      label: route?.meta?.breadcrumb?.label || route?.name?.replace(".", " "),
    });
    if (route?.meta?.breadcrumb?.parent) {
      let parent;
      if (route?.meta?.breadcrumb?.parent === "FromPath") {
        parent = this.getRoute(this.parentPath);
      } else {
        parent = this.getRoute(route.meta.breadcrumb.parent);
      }
      this.addPage(parent);
    }
  }
  public getRoute(name: string): any {
    if (!this.$router.options.routes) {
      return;
    }
    return this.$router.options.routes[0].children?.find(
      (route) => route?.name === name
    );
  }

  @Watch("parentPath")
  public pathChanged() {
    this.buildStack();
  }
}
