
import { Component, Vue, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { withPopper } from "@/plugins/popover";
import ScenarioService from "@/services/scenario.service";
import { ParametersService } from "@/services/parameters.service";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { getModule } from "vuex-module-decorators";
import { operationTypes } from "@/components/run-pricing-model/parameters/constants/operationTypes.const";
import { validPromise } from "@/utils/validPromise";
import { RPMPagesType } from "@/enums/rpm-pages-type";
import EditableRulesList from "@/components/run-pricing-model/EditableRulesList.vue";
import { IScenarioDetails } from "@/interfaces/runPricingModel/scenario/scenarioDetails.interface";
import { RunPricingModelState } from "@/models/runPricingModelState";
import { ScenarioValidations } from "@/constants/validations/scenario.validation";
import { IGlobalRule } from "@/interfaces/runPricingModel/globalRule.interface";
import { ILocalRule } from "@/interfaces/runPricingModel/localRule.interface";

@Component({
  mixins: [validationMixin],
  validations: ScenarioValidations,
  components: {
    "rules-list": EditableRulesList,
  },
  methods: {
    updateName: function (this: any, e: any) {
      this.form.name = e;
      this.$v.form.$touch();
    },
  },
  data() {
    return {
      isRouteLeaving: false,
      isGoingForward: false,
      nextRouteName: String,
      operationTypes,
      nextRoute: Function,
      parametersService: ParametersService,
    };
  },
  async beforeRouteLeave(to, from, next) {
    this.$data.nextRouteName = to.name;
    this.$data.isRouteLeaving = this.isDirty;

    if (this.$data.isGoingForward) {
      this.$data.isRouteLeaving = false;
    }

    if (!this.$data.isRouteLeaving) {
      next();
    }
    this.$data.isRedirectToPrevious = false;
    this.$data.nextRoute = next;
  },
})
export default class Scenario extends Vue {
  // refs

  public popover = withPopper;
  public form: IScenarioDetails = {
    name: "",
    id: null,
    localRules: [],
    globalRules: [],
  };
  public pristineForm: IScenarioDetails = {
    name: "",
    id: null,
    localRules: [],
    globalRules: [],
  };
  rpmModule: RunPricingModelModule;
  public isSaveStateAfterSubmit: boolean = false;
  public isInvalid: boolean = false;
  public isDirty: boolean = false;

  public constructor() {
    super();

    this.rpmModule = getModule(RunPricingModelModule);
  }

  public created(): void {
    this.loadScenarioState();
  }

  private loadScenarioState(): void {
    if (
      this.rpmModule.runPricingModelState &&
      this.rpmModule.runPricingModelState.scenario &&
      (!this.$route.params.id ||
        this.rpmModule.runPricingModelState.scenario.id ===
        +this.$route.params.id)
    ) {
      this.form = this.rpmModule.runPricingModelState.scenario;
      this.pristineForm = JSON.parse(JSON.stringify(this.form));
    } else if (this.$route.params.id) {
      this.loadScenario(+this.$route.params.id);
    }
  }

  public validate(): void {
    this.$v.$touch();
    this.$v.form.$touch();
  }

  public async submit(item: string = ""): Promise<void> {
    this.validate();
    if (this.$v.form.$pending) {
      await validPromise.call(this, "form");
    }
    if (this.$v.form.$invalid) {
      this.$data.isRouteLeaving = false;
      return;
    } else {
      this.form.id ? this.updateScenario() : this.createScenario();
    }
    if (this.$data.nextRoute) {
      this.$data.nextRoute();
      return;
    }
    if (item) {
      this.$router.push({
        name: item,
      });
    }
  }

  private async updateScenario(): Promise<void> {
    if (this.form.id) {
      return ScenarioService.updateScenario(this.form.id, this.form)
        .then(() => {
          Vue.$toast.clear();
          Vue.$toast.success(`Scenario ${this.form.name} updated`);
        })
        .catch((error) => {
          Vue.$toast.clear();
          Vue.$toast.error(error);
        });
    }
  }

  private createScenario(): Promise<void> {
    return ScenarioService.createScenario(this.form)
      .then((scenarioId: number) => {
        this.form.id = scenarioId;
        Vue.$toast.clear();
        Vue.$toast.success(`Scenario ${this.form.name} created`);
      })
      .catch((error) => {
        Vue.$toast.clear();
        switch (error.request.status) {
          case 409:
            Vue.$toast.error("Scenario already exists");
            break;
          default:
            Vue.$toast.error(error);
        }
      });
  }

  public saveState(): void {
    let state = this.rpmModule.runPricingModelState;
    if (!state) {
      state = new RunPricingModelState();
    }

    state.scenario = this.form;
    this.rpmModule.updateState(state);
  }

  @Watch("form", { deep: true })
  public async formChanged() {
    this.isDirty = await this.rpmModule.checkFormIsDirty({
      form: this.form,
      pristineForm: this.pristineForm,
      type: RPMPagesType.Scenario,
    });
  }

  public receiveGlobalRules(rules: IGlobalRule[]): void {
    this.form.globalRules = rules;
  }

  public receiveLocalRules(rules: ILocalRule[]): void {
    this.form.localRules = rules;
  }

  public changingRule(): void {
    this.saveState();
    this.$data.isGoingForward = true;
  }

  public loadScenario(id: number): void {
    ScenarioService.getScenario(id).then((scenario) => {
      this.form = scenario;
      this.pristineForm = JSON.parse(JSON.stringify(scenario));
    });
  }

  public async discardChanges(item: string): Promise<void> {
    if (this.$data.nextRoute) {
      this.$data.nextRoute();
      return;
    }

    this.$router.push({
      name: item,
    });
  }
}
