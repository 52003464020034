
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { JsonQueryRuleSetModel } from '@/components/json-query-builder/models/JsonQueryRuleSetModel';
import _ from 'lodash';
import { JsonQueryRuleCondition } from './enums/JsonQueryRuleCondition';
import { JsonQueryConfigModel } from './models/JsonQueryConfigModel';
import JsonQueryRule from './JsonQueryRule.vue';

@Component({})
export default class JsonQueryGroup extends Vue {
  @Ref() public readonly rules: JsonQueryRule[];
  @Ref() public readonly groups: JsonQueryGroup[];

  @Prop({ required: true, default: {} })
  public currentQuery: JsonQueryRuleSetModel;

  @Prop({ required: false })
  public options: JsonQueryConfigModel[];

  @Prop({ required: false, default: 0 })
  public level: number;

  public queryRuleCondition: any = JsonQueryRuleCondition;

  public validate(): boolean {
    if (this.groups) {
      this.groups.forEach((g) => g.validate());
    }

    let isValid = true;
    this.rules.forEach((r) => {
      if (r.validate()) {
        isValid = false;
      }
    });

    return isValid;
  }

  public addRule(): void {
    const newGroup = {
      operator: null,
      value: '',
      field: '',
      type: null
    };

    Object.defineProperty(newGroup, '_uuid', {
      enumerable: false,
      value: _.uniqueId()
    });

    if (!this.currentQuery.rules) {
      this.currentQuery.rules = [];
    }

    this.currentQuery.rules.push(newGroup);
  }

  public addGroup(): void {
    const newRule = {
      condition: JsonQueryRuleCondition.and,
      rules: [
        {
          operator: null,
          value: '',
          field: '',
          type: null
        }
      ]
    };

    Object.defineProperty(newRule, '_uuid', {
      enumerable: false,
      value: _.uniqueId()
    });

    if (!this.currentQuery.rules) {
      this.currentQuery.rules = [];
    }

    this.currentQuery.rules.push(newRule);
  }

  public deleteGroup(): void {
    const parentRules = (this.$parent as JsonQueryGroup).currentQuery.rules;

    if (parentRules) {
      parentRules.splice(parentRules.indexOf(this.currentQuery), 1);
    }
  }

  public onUpdateCondtion(value: boolean): void {
    this.currentQuery.condition = value
      ? JsonQueryRuleCondition.or
      : JsonQueryRuleCondition.and;
  }
}
