var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('c-card-body',{staticClass:"card-body-container pt-3"},[_c('h5',{staticClass:"m-0 block-title pb-3"},[_vm._v(_vm._s(_vm.isLocal ? "Local" : "Global")+" Rules")]),_c('c-row',{staticClass:"d-flex align-content-center flex-wrap"},[_c('c-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('div',{staticClass:"rules-list"},_vm._l((_vm.rules),function(rule,index){return _c('c-list-group-item',{key:index,staticClass:"d-flex justify-content-left align-items-center pl-0 pr-0",class:{
                'odd-row': index % 2 === 0,
                'even-row': index % 2 !== 0,
              }},[_c('div',{staticClass:"text-truncate primary-text"},[_vm._v(" "+_vm._s(rule.name)+" ")]),_c('div',{staticClass:"align-items-center secondary-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11 text-truncate description-block"},[_vm._v(" "+_vm._s(rule.description)+" ")])])]),_c('div',{staticClass:"btn-group"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":"icons/remove_red_eye.svg","alt":"view"},on:{"click":function($event){return _vm.openRuleModal(rule.id)}}}),(rule.description)?_c('img',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: rule.description,
                    placement: 'left',
                    boundaries: 'viewport',
                  }),expression:"{\n                    content: rule.description,\n                    placement: 'left',\n                    boundaries: 'viewport',\n                  }"}],staticClass:"ml-2 cursor-pointer",style:([
                  rule.description
                    ? { visibility: 'visible' }
                    : { visibility: 'hidden' },
                ]),attrs:{"src":"/icons/tooltip.svg"}}):_vm._e()])])}),1)])])],1)],1),(_vm.ruleModalShow)?_c('parameters-changes-modal',{attrs:{"text":_vm.ruleModalText},on:{"close":_vm.closeRuleModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }