
import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import SubsetRuleService from "@/services/subset-rule.service";
import { validationMixin } from "vuelidate";
import { SubsetRuleValidations } from "@/constants/validations/subset-rule.validation";
import {
  ISubsetRuleDetails
} from "@/interfaces/runPricingModel/subsetRuleDetails.interface";
import { JsonQueryConfigModel } from "../json-query-builder/models/JsonQueryConfigModel";
import { JsonQueryRuleCondition } from "../json-query-builder/enums/JsonQueryRuleCondition";
import { ISubsetRule } from "@/interfaces/runPricingModel/subsetRule.interface";
import { checkIfValid } from "@/utils/validators";
import JsonQueryBuilder from "../json-query-builder/JsonQueryBuilder.vue";
import JsonQueryRule from "../json-query-builder/JsonQueryRule.vue";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { getModule } from "vuex-module-decorators";
import {
  IRunPricingCategory,
  RunPricingColumnType,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import { JsonQueryRuleSetModel } from "../json-query-builder/models/JsonQueryRuleSetModel";
import { validPromise } from "@/utils/validPromise";

@Component({
  mixins: [validationMixin],
  validations: SubsetRuleValidations,
  methods: {
    updateName: function (this: any, e: any) {
      this.form.name = e;
      this.$v.form.$touch();
    },
  },
})
export default class SubsetRuleModal extends Vue {
  @Prop({ required: false, default: null }) public subsetRuleId?: number;

  @Ref() public readonly queryBuilder: JsonQueryBuilder;

  public queryOptions: JsonQueryConfigModel[] = [];
  public form: ISubsetRuleDetails = this.getEmptyForm();
  private runPricingModelModule: RunPricingModelModule;

  public constructor() {
    super();
    if (this.subsetRuleId) {
      this.loadSubsetRule(this.subsetRuleId);
    }
    this.runPricingModelModule = getModule(RunPricingModelModule);
  }

  protected getEmptyForm(): ISubsetRuleDetails {
    return {
      id: this.subsetRuleId,
      name: "",
      rule: this.getEmptyRule(),
    };
  }

  public created(): void {
    if (!this.runPricingModelModule.runPricingParametersInfoState?.categories) {
      this.runPricingModelModule.getRunPricingParametersInfo().then((value) => {
        this.queryOptions = this.getQueryParams(value.categories);
      });
    } else {
      this.queryOptions = this.getQueryParams(
        this.runPricingModelModule.runPricingParametersInfoState.categories
      );
    }
  }

  public isControlValid(fieldName: string): boolean {

    return checkIfValid(this.$v.form, fieldName);
  }

  public validate(): boolean {
    this.$v.$touch();
    this.$v.form.$touch();
    return this.queryBuilder.validate();
  }

  @Emit("close")
  public onClose(): void {
    return;
  }

  @Emit("submit")
  public onSubmit(subsetRule: ISubsetRule): ISubsetRule {
    return subsetRule;
  }

  public async submit(): Promise<void> {
    const isValid = this.validate();
    if ((this.form.rule && this.form.rule?.rules && !this.form.rule.rules.find((r: any) => r.field && r.operator)) || !isValid) {
      return;
    }

    await validPromise.call(this, 'form');
    if (!this.$v.form.$invalid) {
      this.subsetRuleId ? this.update() : this.create();
    }
  }

  public onUpdateBuilderMode(value: boolean): void {
    this.form.rule = value ? null : this.getEmptyRule();
  }

  private getEmptyRule(): JsonQueryRuleSetModel {
    return {
      condition: JsonQueryRuleCondition.and,
      rules: [
        {
          operator: null,
          value: "",
          field: "",
          type: null
        },
      ],
    };
  }

  private getQueryParams(
    categories: IRunPricingCategory[]
  ): JsonQueryConfigModel[] {
    return categories
      .map((category) =>
        category.columns.filter(
          (c) => c.type === RunPricingColumnType.Column && c.isSubset
        )
      )
      .reduce((columns1, columns2) => columns1.concat(columns2))
      .map((c) => {
        const queryConfig: JsonQueryConfigModel = new JsonQueryConfigModel();

        queryConfig.name = c.displayName;
        queryConfig.type = c.dataType;

        return queryConfig;
      })
      .sort((column1, column2) => (column1.name > column2.name ? 1 : -1));
  }

  private create(): void {
    SubsetRuleService.createSubsetRule(this.form).then((response: number) => {
      Vue.$toast.clear();
      Vue.$toast.success(`Subset Rule ${this.form.name} created`);
      this.onSubmit({
        id: response,
        name: this.form.name,
      });
    });
  }

  private update(): void {
    SubsetRuleService.updateSubsetRule(this.form.id as number, this.form).then(
      (response: number) => {
        Vue.$toast.clear();
        Vue.$toast.success(`Subset Rule ${this.form.name} updated`);
        this.onSubmit({
          id: response,
          name: this.form.name,
        });
      }
    );
  }

  private loadSubsetRule(id: number): void {
    SubsetRuleService.getSubsetRule(id).then((response: ISubsetRuleDetails) => {
      this.form = response;

      if (!this.form.rule) {
        this.form.rule = this.getEmptyRule();
      }
    });
  }

}
