
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import {
  IRunPricingCategory,
  IRunPricingColumn,
  RunPricingColumnType,
  RunPricingDataType,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import CommonParam from "./CommonParam.vue";
import VectorParam from "./VectorParam.vue";
import TableParam from "./TableParam.vue";
import ConstructorParam from "./ConstructorParam.vue";
import { IRunPricingRuleSettings } from "@/interfaces/runPricingModel/runPricingRuleSettings.interface";

@Component({
  components: {
    "common-param": CommonParam,
    "vector-param": VectorParam,
    "table-param": TableParam,
    "constructor-param": ConstructorParam,
  },
})
export default class DynamicParams extends Vue {
  // properties
  @Prop({ required: true, default: null }) categories: IRunPricingCategory[];
  @Prop({ required: false, default: false }) isLocal: boolean;
  @Prop({
    required: false,
    default: () => {
      return { isOperationsEnable: false };
    },
  })
  settings: IRunPricingRuleSettings;
  @Prop({
    required: false,
    default: () => {
      return [];
    },
  })
  paramsData: IParamData[];
  // refs

  public get filteredCategories(): IRunPricingCategory[] {
    return [
      ...this.categories.filter((ca) =>
        ca.columns.find(
          (val) =>
            val.type === RunPricingColumnType.Param &&
            this.isLocal === val.isLocal
        )
      ),
    ];
  }

  public getParamData(column: IRunPricingColumn): IParamData | undefined {
    let data = this.paramsData.find((pd) => {
      return pd.columnName === column.columnName;
    });
    if (column.dataType === RunPricingDataType.LIST_FLOAT) {
      if (!data) {
        return data;
      }
      if (!data.value) {
        data.value = {
          segments: null,
          rangeValue: null,
        };
        return data;
      }
    }
    return data;
  }

  public isCommonColumnType(column: IRunPricingColumn): boolean {
    const commonTypesIndexes = [0, 1, 2, 4];

    return (
      column.type === RunPricingColumnType.Param &&
      column.isLocal === this.isLocal &&
      commonTypesIndexes.indexOf(column.dataType) !== -1
    );
  }

  public isVectorColumnType(column: IRunPricingColumn): boolean {
    return (
      column.type === RunPricingColumnType.Param &&
      column.isLocal === this.isLocal &&
      column.dataType === RunPricingDataType.LIST_FLOAT
    );
  }

  public isTableColumnType(column: IRunPricingColumn): boolean {
    return (
      column.type === RunPricingColumnType.TableParam &&
      column.isLocal === this.isLocal &&
      column.dataType === RunPricingDataType.TABLE
    );
  }

  public isConstructorColumnType(column: IRunPricingColumn): boolean {
    return (
      (column.type === RunPricingColumnType.ConstructorParam ||
      column.type === RunPricingColumnType.PairedConstructorParam) &&
      column.isLocal === this.isLocal &&
      column.dataType === RunPricingDataType.STRING
    );
  }

  public writeParamValue(data: IParamData): void {
    const paramIndex: number = this.paramsData.findIndex((pd) => {
      return pd.columnName === data.columnName;
    });

    if (paramIndex === -1) {
      this.paramsData.push(data);
    } else {
      this.paramsData[paramIndex].value = data.value;
    }

    let filteredParamsData = this.paramsData.filter(x => 
      (x.columnType === RunPricingColumnType.PairedConstructorParam && x.inputValue.length > 0) || x.columnType !== RunPricingColumnType.PairedConstructorParam);

    this.$emit("receiveParamsData", filteredParamsData);
  }

  public removeParamsData(data: IParamData): void {
    let filteredParamsData = this.paramsData.filter((pd) => {
      return pd.columnName !== data.columnName;
    });

    this.$emit("receiveParamsData", filteredParamsData);
  }
}
