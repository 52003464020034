import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import ScenarioService from '@/services/scenario.service';

const isUnique = async (value: string | null, currentId?: number) => {
  if (!value || value === '') {
    return true;
  } else {
    const resp = await ScenarioService.getExistScenarioOrDefault(value, currentId);
    return !resp.id || resp.id < 1
  }
}


export const ScenarioValidations = {
  form: {
    name: {
      required,
      maxLength: maxLength(55),
      unique: (value: string, vm: any) => {
        return isUnique(value, vm.id)
      }
    }
  }
};